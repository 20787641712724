<template>
  <pagetemplate mainTitle="Not" imgPath="not.svg">
    <template #smallDrawer>
      <div>
        <div>
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
    <template #content>
      <content-title :text="$t('noteDocument.GenelBakis.baslik')">
      </content-title>
      <p v-html="$t('noteDocument.GenelBakis.Anasayfa.aciklama')"></p>
      <subtitle-template
        id="s1-1"
        :text="$t('noteDocument.GenelBakis.Anasayfa.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.GenelBakis.Anasayfa.bolumler')"></p>
      <yartu-image
        class=""
        imgName="Resim 1"
        imgPath="noteimages/Resim1.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.GenelBakis.Anasayfa.sagBolum')"></p>
      <p v-html="$t('noteDocument.GenelBakis.Anasayfa.solBolum')"></p>
      <yartu-image
        class=""
        imgName="Resim 2"
        imgPath="noteimages/Resim2.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.GenelBakis.Anasayfa.menu')"></p>
      <yartu-image
        class=""
        imgName="Resim 3"
        imgPath="noteimages/Resim3.png"
      ></yartu-image>
      <subtitle-template
        id="s1-2"
        :text="$t('noteDocument.GenelBakis.HizliBaglantiler.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.GenelBakis.HizliBaglantiler.tumNotlar')"></p>
      <yartu-image
        class=""
        imgName="Resim 4"
        imgPath="noteimages/Resim4.png"
      ></yartu-image>
      <span class="text-center">
        <p
          id="s1-1"
          v-html="
            $t('noteDocument.GenelBakis.HizliBaglantiler.pinli', {
              imgPath: require('../assets/noteimages/pinli.png'),
            })
          "
        ></p>
      </span>

      <yartu-image
        class=""
        imgName="Resim 5"
        imgPath="noteimages/Resim5.png"
      ></yartu-image>
      <span class="text-center">
        <p v-html="$t('noteDocument.GenelBakis.HizliBaglantiler.gorevler')"></p>
      </span>

      <yartu-image
        class=""
        imgName="Resim 6"
        imgPath="noteimages/Resim6.png"
      ></yartu-image>
      <span class="text-center"
        ><p
          v-html="$t('noteDocument.GenelBakis.HizliBaglantiler.gorevler2')"
        ></p
      ></span>
      <yartu-image
        class=""
        imgName="Resim 7"
        imgPath="noteimages/Resim7.png"
      ></yartu-image>
      <subtitle-template
        id="s1-3"
        :text="$t('noteDocument.GenelBakis.YapiskanNotlar.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.GenelBakis.YapiskanNotlar.olusturma')"></p>
      <yartu-image
        class=""
        imgName="Resim 8"
        imgPath="noteimages/Resim8.png"
      ></yartu-image>
      <p
        v-html="
          $t('noteDocument.GenelBakis.YapiskanNotlar.kisayol', {
            imgPath: require('../assets/noteimages/sticky_icon.png'),
          })
        "
      ></p>
      <yartu-image
        class="w-1/3"
        imgName="Resim 9"
        imgPath="noteimages/Resim9.png"
      ></yartu-image>
      <p>
        <span> {{ $t("noteDocument.GenelBakis.YapiskanNotlar.link") }} </span>
        <span
          @click="scrollToElement('s4-1')"
          class="text-BLUE font-semibold cursor-pointer"
        >
          {{ $t("noteDocument.GenelBakis.YapiskanNotlar.link2") }}
        </span>
        <span> {{ $t("noteDocument.GenelBakis.YapiskanNotlar.link3") }} </span>
      </p>
      <subtitle-template
        id="s1-4"
        :text="$t('noteDocument.GenelBakis.Payasilan.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.GenelBakis.Payasilan.paylas1')"></p>
      <yartu-image
        class=""
        imgName="Resim 11"
        imgPath="noteimages/Resim11.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.GenelBakis.Payasilan.paylas2')"></p>
      <yartu-image
        class=""
        imgName="Resim 12"
        imgPath="noteimages/Resim12.png"
      ></yartu-image>
      <subtitle-template
        id="s1-5"
        :text="$t('noteDocument.GenelBakis.Yildizli.baslik')"
      ></subtitle-template>
      <p
        v-html="
          $t('noteDocument.GenelBakis.Yildizli.aciklama', {
            imgPath: require('../assets/noteimages/yildizla.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 10"
        imgPath="noteimages/Resim10.png"
      ></yartu-image>
      <subtitle-template
        id="s1-6"
        :text="$t('noteDocument.GenelBakis.Arsiv.baslik')"
      ></subtitle-template>
      <p
        v-html="
          $t('noteDocument.GenelBakis.Arsiv.aciklama', {
            imgPath: require('../assets/noteimages/arsivle.png'),
          })
        "
      ></p>
      <subtitle-template
        id="s1-7"
        :text="$t('noteDocument.GenelBakis.NotDefterlerim.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.GenelBakis.NotDefterlerim.liste')"></p>
      <yartu-image
        class=""
        imgName="Resim 12"
        imgPath="noteimages/Resim12.png"
      ></yartu-image>
      <p
        v-html="
          $t('noteDocument.GenelBakis.NotDefterlerim.paylas', {
            imgPath: require('../assets/noteimages/paylasimikonu.png'),
          })
        "
      ></p>
      <p
        v-html="
          $t('noteDocument.GenelBakis.NotDefterlerim.menu', {
            imgPath: require('../assets/noteimages/menu.png'),
          })
        "
      ></p>
      <subtitle-template
        id="s1-8"
        :text="$t('noteDocument.GenelBakis.Etiket.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.GenelBakis.Etiket.liste')"></p>
      <yartu-image
        class=""
        imgName="Resim 13"
        imgPath="noteimages/Resim13.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.GenelBakis.Etiket.etiketliNotlar')"></p>
      <yartu-image
        class=""
        imgName="Resim 14"
        imgPath="noteimages/Resim14.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.GenelBakis.Etiket.ekleme')"></p>
      <yartu-image
        class=""
        imgName="Resim 15"
        imgPath="noteimages/Resim39.png"
      ></yartu-image>
      <content-title :text="$t('noteDocument.DefterKullanimi.baslik')">
      </content-title>
      <subtitle-template
        id="s2-1"
        :text="$t('noteDocument.DefterKullanimi.Filtreleme.baslik')"
      ></subtitle-template>
      <p
        v-html="
          $t('noteDocument.DefterKullanimi.Filtreleme.erisim', {
            imgPath: require('../assets/noteimages/filtrele.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 15"
        imgPath="noteimages/Resim17.png"
      ></yartu-image>
      <p
        v-html="$t('noteDocument.DefterKullanimi.Filtreleme.filtreCEsitleri')"
      ></p>
      <subtitle-template
        id="s2-2"
        :text="$t('noteDocument.DefterKullanimi.Sıralama.baslik')"
      ></subtitle-template>
      <yartu-image
        class=""
        imgName=""
        imgPath="noteimages/siralama_butons.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.DefterKullanimi.Sıralama.siralamaTipi')"></p>
      <yartu-image
        class=""
        imgName="Resim 16"
        imgPath="noteimages/Resim16.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.DefterKullanimi.Sıralama.siralamaSekli')"></p>
      <yartu-image
        class=""
        imgName="Resim 17"
        imgPath="noteimages/Resim15.png"
      ></yartu-image>
      <subtitle-template
        id="s2-3"
        :text="$t('noteDocument.DefterKullanimi.Olusturma.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.DefterKullanimi.Olusturma.aciklama')"></p>
      <yartu-image
        class=""
        imgName="Resim 18"
        imgPath="noteimages/Resim18.png"
      ></yartu-image>
      <subtitle-template
        id="s2-4"
        :text="$t('noteDocument.DefterKullanimi.Paylasim.baslik')"
      ></subtitle-template>
      <p
        v-html="
          $t('noteDocument.DefterKullanimi.Paylasim.paylasma', {
            imgPath: require('../assets/noteimages/paylasimikonu.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 19"
        imgPath="noteimages/Resim19.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.DefterKullanimi.Paylasim.izin')"></p>
      <content-title :text="$t('noteDocument.NotOlusturma.baslik')">
      </content-title>
      <subtitle-template
        id="s3-1"
        :text="$t('noteDocument.NotOlusturma.OlusturmaSecenekleri.baslik')"
      ></subtitle-template>
      <p
        v-html="$t('noteDocument.NotOlusturma.OlusturmaSecenekleri.aciklama')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 20"
        imgPath="noteimages/Resim20.png"
      ></yartu-image>
      <p
        v-html="$t('noteDocument.NotOlusturma.OlusturmaSecenekleri.yenidefter')"
      ></p>
      <subtitle-template
        id="s3-2"
        :text="$t('noteDocument.NotOlusturma.NotDuzenleme.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.NotOlusturma.NotDuzenleme.aciklama')"></p>
      <yartu-image
        class=""
        imgName="Resim 21"
        imgPath="noteimages/Resim21.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.NotOlusturma.NotDuzenleme.sirasiyla')"></p>
      <div class="px-6 flex flex-col gap-4">
        <sentace-with-image
          img-path="noteimages/sticky_icon.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.goruntu')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/link.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.link')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/paragraf.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.paragraf')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/italik.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.italik')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/numaralandir.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.numaralandir')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/noktali.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.noktaliListe')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/hizala.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.hizala')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/tablo.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.tabloEkle')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/kod_ekle.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.kodEkle')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/ayir.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.ayir')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/altini_ciz.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.altCizgiREngi')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/renk.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.yaziRengi')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/geri.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.ileriGeri')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/menu.png"
          :text="$t('noteDocument.NotOlusturma.NotDuzenleme.temizle')"
        ></sentace-with-image>
      </div>
      <subtitle-template
        id="s3-3"
        :text="$t('noteDocument.NotOlusturma.IslemMenusu.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.NotOlusturma.IslemMenusu.aciklama')"></p>
      <yartu-image
        class=""
        imgName="Resim 22"
        imgPath="noteimages/Resim31.png"
      ></yartu-image>
      <div class="px-6 flex flex-col gap-4">
        <sentace-with-image
          img-path="noteimages/yildizla.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.yildizla')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/pinli.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.pinle')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/tasi.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.tasi')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/arsivle.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.arsivle')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/kopyala.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.kopyala')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/dublicate.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.burayaKopyala')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/yazdir.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.yazdir')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/sil.png"
          :text="$t('noteDocument.NotOlusturma.IslemMenusu.sil')"
        ></sentace-with-image>
      </div>
      <content-title :text="$t('noteDocument.YapiskanNotlar.baslik')">
      </content-title>
      <subtitle-template
        id="s4-1"
        :text="$t('noteDocument.YapiskanNotlar.baslik')"
      ></subtitle-template>
      <p v-html="$t('noteDocument.YapiskanNotlar.aciklama')"></p>
      <p
        v-html="
          $t('noteDocument.YapiskanNotlar.kisayol', {
            imgPath: require('../assets/noteimages/sticky_icon.png'),
            imgPath2: require('../assets/noteimages/arti.png'),
          })
        "
        id="s4-2"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 23"
        imgPath="noteimages/Resim32.png"
      ></yartu-image>
      <p v-html="$t('noteDocument.YapiskanNotlar.duzenleme')"></p>
      <yartu-image
        class=""
        imgName="Resim 24"
        imgPath="noteimages/Resim34.png"
      ></yartu-image>
      <span class="text-center">
        <p v-html="$t('noteDocument.YapiskanNotlar.erisim')"></p>
      </span>

      <yartu-image
        class=""
        imgName="Resim 25"
        imgPath="noteimages/Resim33.png"
      ></yartu-image>
      <p
        id="s4-3"
        v-html="$t('noteDocument.YapiskanNotlar.düzenelemeAciklama')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 26"
        imgPath="noteimages/Resim35.png"
      ></yartu-image>
      <p>
        <span> {{ $t("noteDocument.YapiskanNotlar.yaziTipi") }} </span>
        <span
          @click="scrollToElement('s3-2')"
          class="font-semibold text-BLUE cursor-pointer"
        >
          {{ $t("noteDocument.YapiskanNotlar.yaziTipi2") }}
        </span>
        <span> {{ $t("noteDocument.YapiskanNotlar.yaziTipi3") }} </span>
      </p>
      <div class="px-6 flex flex-col gap-4">
        <sentace-with-image
          img-path="noteimages/bitis.png"
          :text="$t('noteDocument.YapiskanNotlar.bitisTarihi')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/resim_beyaz.png"
          :text="$t('noteDocument.YapiskanNotlar.resimEkle')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/link_beyaz.png"
          :text="$t('noteDocument.YapiskanNotlar.linkEkleme')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/palet.png"
          :text="$t('noteDocument.YapiskanNotlar.renkDegistirme')"
        ></sentace-with-image>
      </div>
      <p v-html="$t('noteDocument.YapiskanNotlar.menu')"></p>
      <div class="px-6 flex flex-col gap-4">
        <sentace-with-image
          img-path="noteimages/menu.png"
          :text="$t('noteDocument.YapiskanNotlar.depomaKaydet')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/nota_donustur.png"
          :text="$t('noteDocument.YapiskanNotlar.notaDonustur')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/dublicate.png"
          :text="$t('noteDocument.YapiskanNotlar.kopyasiniOlustur')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="noteimages/sil.png"
          :text="$t('noteDocument.YapiskanNotlar.sil')"
        ></sentace-with-image>
      </div>
    </template>
    <template #drawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";

import { onMounted, ref, watch } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";

import ComplateDrawer from "@/components/ComplateDrawer.vue";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import { useRoute } from "vue-router";
import SentaceWithImage from "../components/SentaceWithImage.vue";

const drawerStatus = ref(false);

const route = useRoute();

const scrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};
watch(route, (newroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.Id !== "") {
    console.log("data is", route.query.Id);
    scrollToElement(route.query.Id);
  }
});

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Genel Bakış ",
    elementId: "s1",
    subtitles: [
      {
        title: "Anasayfa",
        elementId: "s1-1",
      },
      {
        title: "Hızlı Bağlantılar",
        elementId: "s1-2",
      },
      {
        title: "Yapışkan Notlar",
        elementId: "s1-3",
      },

      {
        title: "Paylaşılan Notlar",
        elementId: "s1-4",
      },
      {
        title: "Yıldızlı Notlar",
        elementId: "s1-5",
      },
      {
        title: "Arşiv",
        elementId: "s1-6",
      },
      {
        title: "Not Defterlerim",
        elementId: "s1-7",
      },
      {
        title: "Etiketler",
        elementId: "s1-8",
      },
    ],
  },
  {
    title: "Not Defteri Kullanımı",
    elementId: "s2",
    subtitles: [
      {
        title: "Fİltreleme",
        elementId: "s2-1",
      },
      {
        title: "Sıralama",
        elementId: "s2-2",
      },
      {
        title: "Oluşturma",
        elementId: "s2-3",
      },
      {
        title: "Paylaşım",
        elementId: "s2-4",
      },
    ],
  },
  {
    title: "Not Oluşturma",
    elementId: "s3",
    subtitles: [
      {
        title: "Oluşturma Seçenekleri",
        elementId: "s3-1",
      },
      {
        title: "Not Düzenleme",
        elementId: "s3-2",
      },
      {
        title: "İşlem Menüsü",
        elementId: "s3-3",
      },
    ],
  },
  {
    title: "Yapışkan Notlar",
    elementId: "s4",
    subtitles: [
      {
        title: "Nedir?",
        elementId: "s4-1",
      },
      {
        title: "Erişim",
        elementId: "s4-2",
      },
      {
        title: "Düzenleme",
        elementId: "s4-3",
      },
    ],
  },
]);
</script>
<script></script>
