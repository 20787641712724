<template>
  <pagetemplate class="" isSync main-title="Senkronizasyon" imgPath="proje.svg">
    <template #smallDrawer>
      <div>
        <div class="" isSync>
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
    <template #content>
      <content-title
        :text="$t('syncroniseDocument.nedir.title')"
      ></content-title>
      <p v-html="$t('syncroniseDocument.nedir.aciklama1')"></p>
      <p v-html="$t('syncroniseDocument.nedir.aciklama2')"></p>
      <content-title
        id="s1"
        :text="$t('syncroniseDocument.android.title')"
      ></content-title>
      <subtitle-template
        id="s1-1"
        :text="$t('syncroniseDocument.android.subtitle1')"
      >
      </subtitle-template>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım1')"
      ></p>
      <yartu-image
        imgName="Resim 1"
        isSync
        imgPath="androidsyncimages/1.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım2')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 2"
        imgPath="androidsyncimages/2.png"
      ></yartu-image>
      <p class="text-center">
        <span v-html="$t('syncroniseDocument.android.adım31')"></span>
        <span
          @click="scrollToElement('s2-1')"
          class="text-BLUE font-semibold cursor-pointer"
        >
          {{ $t("syncroniseDocument.android.adım32") }}</span
        >
        <span> {{ $t("syncroniseDocument.android.adım33") }} </span>
      </p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 3"
        imgPath="androidsyncimages/3.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım4')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 4"
        imgPath="androidsyncimages/4.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım5')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 5"
        imgPath="androidsyncimages/5.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım6')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 6"
        imgPath="androidsyncimages/6.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım7')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 7"
        imgPath="androidsyncimages/7.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım8')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 8"
        imgPath="androidsyncimages/9.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım9')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 9"
        imgPath="androidsyncimages/11.png"
      ></yartu-image>
      <subtitle-template
        id="s1-2"
        :text="$t('syncroniseDocument.android.subtitle2')"
      >
      </subtitle-template>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım10')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 10"
        imgPath="androidsyncimages/12.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım11')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 11"
        imgPath="androidsyncimages/13.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım12')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 12"
        imgPath="androidsyncimages/15.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım13')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 13"
        imgPath="androidsyncimages/16.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım14')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 14"
        imgPath="androidsyncimages/17.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım15')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 15"
        imgPath="androidsyncimages/19.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım16')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 16"
        imgPath="androidsyncimages/20.png"
      ></yartu-image>
      <subtitle-template
        id="s1-3"
        :text="$t('syncroniseDocument.android.subtitle3')"
      >
      </subtitle-template>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım17')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 17"
        imgPath="androidsyncimages/21.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım18')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 18"
        imgPath="androidsyncimages/22.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım19')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 19"
        imgPath="androidsyncimages/23.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım20')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 20"
        imgPath="androidsyncimages/25.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım21')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 21"
        imgPath="androidsyncimages/26.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım22')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 22"
        imgPath="androidsyncimages/27.png"
      ></yartu-image>
      <p
        class="text-center"
        v-html="$t('syncroniseDocument.android.adım23')"
      ></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 23"
        imgPath="androidsyncimages/28.png"
      ></yartu-image>
      <content-title
        id="s1"
        :text="$t('syncroniseDocument.Ios.title')"
      ></content-title>
      <subtitle-template
        id="s2-1"
        :text="$t('syncroniseDocument.Ios.subtitle1')"
      >
      </subtitle-template>
      <p v-html="$t('syncroniseDocument.Ios.giris')"></p>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım1')"></p>
      <yartu-image
        imgName="Resim 24"
        isSync
        imgPath="iossyncimages/1.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım2')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 1"
        imgPath="iossyncimages/2.png"
      ></yartu-image>
      <p class="text-center">
        <span v-html="$t('syncroniseDocument.Ios.adım31')"></span>
        <span
          @click="scrollToElement('s1-1')"
          class="text-BLUE font-semibold cursor-pointer"
        >
          {{ $t("syncroniseDocument.Ios.adım32") }}</span
        >
        <span> {{ $t("syncroniseDocument.Ios.adım33") }} </span>
      </p>

      <yartu-image
        class=""
        isSync
        imgName="Resim 2"
        imgPath="iossyncimages/3.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım4')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 3"
        imgPath="iossyncimages/4.png"
      ></yartu-image>
      <subtitle-template
        id="s2-2"
        :text="$t('syncroniseDocument.Ios.subtitle2')"
      >
      </subtitle-template>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım5')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 4"
        imgPath="iossyncimages/5.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım6')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 5"
        imgPath="iossyncimages/6.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım7')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 6"
        imgPath="iossyncimages/7.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım8')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 7"
        imgPath="iossyncimages/8.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım9')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 8"
        imgPath="iossyncimages/9.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım10')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 9"
        imgPath="iossyncimages/10.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım11')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 10"
        imgPath="iossyncimages/11.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım12')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 11"
        imgPath="iossyncimages/12.png"
      ></yartu-image>
      <subtitle-template
        id="s2-3"
        :text="$t('syncroniseDocument.Ios.subtitle3')"
      >
      </subtitle-template>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım13')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 12"
        imgPath="iossyncimages/13.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım14')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 13"
        imgPath="iossyncimages/16.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım15')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 14"
        imgPath="iossyncimages/17.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım16')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 15"
        imgPath="iossyncimages/18.png"
      ></yartu-image>
      <p class="text-center" v-html="$t('syncroniseDocument.Ios.adım17')"></p>
      <yartu-image
        class=""
        isSync
        imgName="Resim 16"
        imgPath="iossyncimages/19.png"
      ></yartu-image>
    </template>
    <template #drawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";

import { onMounted, ref, watch } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";

import ComplateDrawer from "@/components/ComplateDrawer.vue";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import { useRoute } from "vue-router";

const drawerStatus = ref(false);

const route = useRoute();

const scrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};
watch(route, (newroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.Id !== "") {
    console.log("data is", route.query.Id);
    scrollToElement(route.query.Id);
  }
});

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Senkronizasyon Nedir? ",
    elementId: "s1",
    subtitles: [
      {
        title: "Senkronizasyon Nedir, Ne İşe Yarar?",
        elementId: "s2-1",
      },
    ],
  },
  {
    title: "Android Senkronizasyon ",
    elementId: "s1",
    subtitles: [
      {
        title: "DAvx5 Kurulumu",
        elementId: "s1-1",
      },
      {
        title: "Hesap Oluşturma",
        elementId: "s1-2",
      },
      {
        title: "Senkronizasyon İşlemi",
        elementId: "s1-3",
      },
    ],
  },
  {
    title: "Ios Senkronizasyon ",
    elementId: "s1",
    subtitles: [
      {
        title: "Apk Kurulumu",
        elementId: "s2-1",
      },
      {
        title: "Konfigürasyon Girişi",
        elementId: "s2-2",
      },
      {
        title: "Senkronizasyon İşlemi",
        elementId: "s2-3",
      },
    ],
  },
]);
</script>
<script></script>
