import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import uploadData from "./loadData";

import YartuUIKit from "@yartu/ui-kit";
import { ref } from "vue";
import "../src/assets/css/input.css";
import "../src/assets/yartu-icons/yartu-icons.css";
import InstantSearch from "vue-instantsearch/vue3/es";
import algoliasearch from "algoliasearch";

const i18n = createI18n({
  locale: "tr", // Varsayılan olarak kullanılacak dil
  // fallbackLocale: "en", // Yedek dil, çeviri bulunamadığında kullanılacak dil
  messages: {
    tr: require("./i18n/tr.json"),
    en: require("../src/i18n/en.json"),
  },
});

const app = createApp(App);

app.use(router).use(i18n).use(YartuUIKit).use(InstantSearch).mount("#app");

uploadData();

export default app;
