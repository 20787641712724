<template>
  <pagetemplate
    :main-title="$t('emailDocument.pageTitle')"
    img-path="amail.svg"
  >
    <template #smallDrawer>
      <div>
        <complate-drawer
          :menuList="menus"
          @clicked="scrollToElement"
        ></complate-drawer>
      </div>
    </template>
    <template #content>
      <content-title
        id="s1"
        :text="$t('emailDocument.genelEposta.title')"
      ></content-title
      ><subtitle-template
        :text="$t('emailDocument.genelEposta.epostaOlusturma.title')"
      >
      </subtitle-template>
      <p
        id="s1-1"
        v-html="
          $t('emailDocument.genelEposta.epostaOlusturma.giris', {
            imgPath: require('../assets/emailimages/iconsbuttons/yenibutton.svg'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 1"
        imgPath="emailimages/Resim1.png"
      ></yartu-image>
      <subtitle-template text="Pencere Görünümü"></subtitle-template>
      <p
        id="s1-2"
        v-html="
          $t('emailDocument.genelEposta.pencereGörünümü.giris', {
            imgPath: require('../assets/emailimages/iconsbuttons/pencere.png'),
          })
        "
      ></p>
      <yartu-image
        imgName="Resim 2"
        imgPath="emailimages/Resim2.png"
      ></yartu-image>
      <subtitle-template text="E-mail Gönderme"> </subtitle-template>
      <p id="s1-3"></p>
      <yartu-image
        imgName="Resim 3"
        imgPath="emailimages/Resim3.png"
      ></yartu-image>
      <p
        id=""
        v-html="
          $t('emailDocument.genelEposta.emailGonderme.kime', {
            imgPath: require('../assets/emailimages/iconsbuttons/pencere.png'),
          })
        "
      ></p>
      <p v-html="$t('emailDocument.genelEposta.emailGonderme.ccBcc')"></p>
      <div class="p-4 border-2 rounded-xl">
        <div class="flex flex-col">
          <p
            class="italic text-sm"
            v-html="$t('emailDocument.genelEposta.emailGonderme.not1')"
          ></p>
          <br />
          <p
            class="italic text-sm"
            v-html="$t('emailDocument.genelEposta.emailGonderme.not2')"
          ></p>
        </div>
      </div>

      <p id="" v-html="$t('emailDocument.genelEposta.emailGonderme.konu')"></p>

      <content-title id="s2" text="E-Posta İşlemleri"></content-title>
      <subtitle-template
        id="s2-40"
        text="E-posta Biçimlendirme"
      ></subtitle-template>
      <p
        v-html="
          $t('emailDocument.epostaIslemleri.epostaBicimlendirme.aracCubugu')
        "
      ></p>
      <yartu-image
        imgName="Resim 4"
        imgPath="emailimages/Resim4.png"
      ></yartu-image>

      <div class="flex flex-row gap-6">
        <div class="flex flex-col justify-center items-start gap-2">
          <sentace-with-image
            img-path="emailimages/iconsbuttons/metintipi.png"
            :text="
              $t('emailDocument.epostaIslemleri.epostaBicimlendirme.metinTipi')
            "
          ></sentace-with-image>
          <sentace-with-image
            img-path="emailimages/iconsbuttons/metin.png"
            :text="
              $t('emailDocument.epostaIslemleri.epostaBicimlendirme.metin')
            "
          ></sentace-with-image>
          <sentace-with-image
            img-path="emailimages/iconsbuttons/metinliste.png"
            :text="
              $t('emailDocument.epostaIslemleri.epostaBicimlendirme.metinListe')
            "
          ></sentace-with-image>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 5"
            imgPath="emailimages/Resim5.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden">
          <yartu-image
            class="mx-auto"
            imgName="Resim 5"
            imgPath="emailimages/Resim5.png"
          ></yartu-image>
        </div>
      </div>
      <div class="flex flex-row w-full gap-6">
        <div class="flex flex-col w-full justify-center items-start gap-2">
          <sentace-with-image
            id="s2-2"
            img-path="emailimages/iconsbuttons/hizalama.png"
            :text="
              $t('emailDocument.epostaIslemleri.epostaBicimlendirme.hizalama')
            "
          ></sentace-with-image>
          <sentace-with-image
            img-path="emailimages/iconsbuttons/ayrac.png"
            :text="
              $t('emailDocument.epostaIslemleri.epostaBicimlendirme.ayrac')
            "
          ></sentace-with-image>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 6"
            imgPath="emailimages/Resim6.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden">
          <yartu-image
            class=""
            imgName="Resim 6"
            imgPath="emailimages/Resim6.png"
          ></yartu-image>
        </div>
      </div>

      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2">
          <sentace-with-image
            id="s2-3"
            img-path="emailimages/iconsbuttons/backround.png"
            :text="$t('emailDocument.epostaIslemleri.epostaBicimlendirme.renk')"
          ></sentace-with-image>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 7"
            imgPath="emailimages/Resim7.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 7"
            imgPath="emailimages/Resim7.png"
          ></yartu-image>
        </div>
      </div>
      <sentace-with-image
        id="s2-4"
        img-path="emailimages/iconsbuttons/ilerial.png"
        :text="$t('emailDocument.epostaIslemleri.epostaBicimlendirme.ilerial')"
      ></sentace-with-image>
      <sentace-with-image
        img-path="emailimages/iconsbuttons/gerial.png"
        :text="$t('emailDocument.epostaIslemleri.epostaBicimlendirme.gerial')"
      ></sentace-with-image>
      <sentace-with-image
        id="s2-5"
        img-path="emailimages/iconsbuttons/attach.png"
        :text="$t('emailDocument.epostaIslemleri.epostaBicimlendirme.ekleme')"
      ></sentace-with-image>
      <yartu-image
        class="mx-auto"
        imgName="Resim 8"
        imgPath="emailimages/Resim8.png"
      ></yartu-image>
      <p
        v-html="
          $t('emailDocument.epostaIslemleri.epostaBicimlendirme.ekIslemleri')
        "
      ></p>

      <sentace-with-image
        id="s2-6"
        img-path="emailimages/iconsbuttons/image.png"
        :text="
          $t('emailDocument.epostaIslemleri.epostaBicimlendirme.resimEkleme')
        "
      >
      </sentace-with-image>
      <yartu-image
        class="mx-auto"
        imgName="Resim 9"
        imgPath="emailimages/Resim9.png"
      ></yartu-image>
      <div class="flex flex-row gap-6">
        <div class="flex flex-col justify-center items-start gap-2">
          <sentace-with-image
            id="s2-7"
            img-path="emailimages/iconsbuttons/chain.png"
            :text="
              $t('emailDocument.epostaIslemleri.epostaBicimlendirme.linkEkleme')
            "
          >
          </sentace-with-image>

          <yartu-image
            class="xs:hidden"
            imgName="Resim 10"
            imgPath="emailimages/Resim10.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 10"
            imgPath="emailimages/Resim10.png"
          ></yartu-image>
        </div>
      </div>
      <sentace-with-image
        img-path="emailimages/iconsbuttons/kalem.png"
        :text="
          $t('emailDocument.epostaIslemleri.epostaBicimlendirme.imzaEkleme')
        "
      >
      </sentace-with-image>
      <p
        v-html="
          $t('emailDocument.epostaIslemleri.epostaBicimlendirme.imzaEkleme2', {
            imgPath: require('../assets/emailimages/iconsbuttons/addsignature.png'),
          })
        "
      ></p>
      <yartu-image
        img-name="Resim 11 "
        img-path="emailimages/Resim11.png"
      ></yartu-image>
      <sentace-with-image
        id="s2-8"
        img-path="emailimages/iconsbuttons/template.png"
        :text="
          $t('emailDocument.epostaIslemleri.epostaBicimlendirme.sablonEkleme')
        "
      >
      </sentace-with-image>
      <yartu-image
        class="w-9/12"
        img-name="Resim 12 "
        img-path="emailimages/Resim12.png"
      ></yartu-image>
      <yartu-image
        class="w-9/12"
        img-name="Resim 13 "
        img-path="emailimages/Resim13.png"
      ></yartu-image>

      <subtitle-template
        id="s2-9"
        :text="$t('emailDocument.epostaIslemleri.epostaIcerigi.title')"
      ></subtitle-template>

      <p v-html="$t('emailDocument.epostaIslemleri.epostaIcerigi.giris')"></p>
      <p
        id="s2-1"
        v-html="$t('emailDocument.epostaIslemleri.epostaAracCubugu.giris')"
      ></p>

      <yartu-image
        class=""
        img-name="Resim 14"
        img-path="emailimages/Resim14.png"
      ></yartu-image>
      <div>
        <sentace-with-image
          id="s2-10"
          img-path="emailimages/iconsbuttons/okundu.png"
          :text="$t('emailDocument.epostaIslemleri.epostaAracCubugu.okundu')"
        >
        </sentace-with-image>
        <sentace-with-image
          id="s2-11"
          img-path="emailimages/iconsbuttons/print.png"
          :text="$t('emailDocument.epostaIslemleri.epostaAracCubugu.yazdırma')"
        >
        </sentace-with-image>
        <sentace-with-image
          id="s2-12"
          img-path="emailimages/iconsbuttons/yildiz.png"
          :text="$t('emailDocument.epostaIslemleri.epostaAracCubugu.favoriler')"
        >
        </sentace-with-image>
      </div>

      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-center gap-2">
          <sentace-with-image
            id="s2-13"
            img-path="emailimages/iconsbuttons/tası.png"
            :text="$t('emailDocument.epostaIslemleri.epostaAracCubugu.tasima')"
          >
          </sentace-with-image>
          <yartu-image
            class="xs:hidden"
            imgName="Resim 15"
            imgPath="emailimages/Resim15.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 15"
            imgPath="emailimages/Resim15.png"
          ></yartu-image>
        </div>
      </div>
      <sentace-with-image
        id="s2-14"
        img-path="emailimages/iconsbuttons/yanitla.png"
        :text="$t('emailDocument.epostaIslemleri.epostaAracCubugu.yanitla')"
      >
      </sentace-with-image>
      <sentace-with-image
        img-path="emailimages/iconsbuttons/tumyanitla.png"
        :text="
          $t('emailDocument.epostaIslemleri.epostaAracCubugu.topluYanitla')
        "
      >
      </sentace-with-image>
      <sentace-with-image
        id="s2-15"
        img-path="emailimages/iconsbuttons/ilet.png"
        :text="$t('emailDocument.epostaIslemleri.epostaAracCubugu.ilet')"
      >
      </sentace-with-image>
      <sentace-with-image
        id="s2-16"
        img-path="emailimages/iconsbuttons/silme.png"
        :text="$t('emailDocument.epostaIslemleri.epostaAracCubugu.silme')"
      >
      </sentace-with-image>
      <p
        id="s2-17"
        v-html="$t('emailDocument.epostaIslemleri.ekListeleme.ekler')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 16"
        imgPath="emailimages/Resim16.png"
      ></yartu-image>
      <div class="p-4 border-2 rounded-xl">
        <p
          class="italic text-sm"
          v-html="$t('emailDocument.epostaIslemleri.ekListeleme.ekIndirme')"
        ></p>
      </div>
      <p v-html="$t('emailDocument.epostaIslemleri.ekListeleme.menu')"></p>

      <yartu-image
        class=""
        imgName="Resim 17"
        imgPath="emailimages/Resim17.png"
      ></yartu-image>
      <p v-html="$t('emailDocument.epostaIslemleri.cokluIslem.cokluMail')"></p>
      <yartu-image
        class=""
        imgName="Resim 18"
        imgPath="emailimages/Resim18.png"
      ></yartu-image>
      <sentace-with-image
        id="s2-19"
        img-path="emailimages/iconsbuttons/tası.png"
        :text="$t('emailDocument.epostaIslemleri.cokluIslem.tası')"
      >
      </sentace-with-image>
      <sentace-with-image
        id="s2-20"
        img-path="emailimages/iconsbuttons/yildiz.png"
        :text="$t('emailDocument.epostaIslemleri.cokluIslem.favorile')"
      >
      </sentace-with-image>
      <sentace-with-image
        id="s2-21"
        img-path="emailimages/iconsbuttons/silme.png"
        :text="$t('emailDocument.epostaIslemleri.cokluIslem.sil')"
      >
      </sentace-with-image>
      <subtitle-template
        id="s2-41"
        :text="$t('emailDocument.epostaIslemleri.siralamaFiltreleme.title')"
      ></subtitle-template>
      <p
        v-html="$t('emailDocument.epostaIslemleri.siralamaFiltreleme.giris')"
      ></p>

      <div class="flex flex-row gap-6 mx-auto">
        <div id="s2-22" class="flex flex-col justify-center items-start gap-2">
          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.dosyala')
            "
          ></p>
          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.hepsi')
            "
          ></p>
          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.okundu')
            "
          ></p>
          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.okunmadı')
            "
          ></p>
          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.sırala')
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 20"
            imgPath="emailimages/Resim20.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 20"
            imgPath="emailimages/Resim20.png"
          ></yartu-image>
        </div>
      </div>
      <div class="flex flex-row gap-6 mx-auto">
        <div id="s2-23" class="flex flex-col justify-center items-start gap-2">
          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.tarih')
            "
          ></p>
          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.kimden')
            "
          ></p>

          <p
            v-html="
              $t('emailDocument.epostaIslemleri.siralamaFiltreleme.boyut')
            "
          ></p>
          <p
            v-html="$t('emailDocument.epostaIslemleri.siralamaFiltreleme.konu')"
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 21"
            imgPath="emailimages/Resim21.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 21"
            imgPath="emailimages/Resim21.png"
          ></yartu-image>
        </div>
      </div>
      <content-title
        :text="$t('emailDocument.klasorIslemleri.title')"
      ></content-title>
      <p
        id="s2-42"
        v-html="$t('emailDocument.klasorIslemleri.tanimliKlasorler.giris')"
      ></p>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-4">
          <p
            id="s3-1"
            v-html="
              $t('emailDocument.klasorIslemleri.tanimliKlasorler.gelenKutusu')
            "
          ></p>
          <p
            v-html="
              $t('emailDocument.klasorIslemleri.tanimliKlasorler.gönderilen')
            "
          ></p>
          <p
            id="s3-2"
            v-html="
              $t('emailDocument.klasorIslemleri.tanimliKlasorler.yildizli')
            "
          ></p>
          <p
            id="s3-3"
            v-html="
              $t('emailDocument.klasorIslemleri.tanimliKlasorler.silinenler')
            "
          ></p>

          <p
            id="s3-4"
            v-html="$t('emailDocument.klasorIslemleri.tanimliKlasorler.arsiv')"
          ></p>
          <p
            id="s3-5"
            v-html="
              $t('emailDocument.klasorIslemleri.tanimliKlasorler.istenmeyen')
            "
          ></p>
          <p
            id="s3-6"
            v-html="
              $t('emailDocument.klasorIslemleri.tanimliKlasorler.tumPostalar')
            "
          ></p>
          <p
            id="s3-7"
            v-html="
              $t('emailDocument.klasorIslemleri.tanimliKlasorler.klasorler', {
                imgPath: require('../assets/emailimages/iconsbuttons/klasorolustur.png'),
              })
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 22"
            imgPath="emailimages/Resim22.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 22"
            imgPath="emailimages/Resim22.png"
          ></yartu-image>
        </div>
      </div>
      <yartu-image
        class="w-1/2"
        imgName="Resim 23"
        imgPath="emailimages/Resim23.png"
      ></yartu-image>
      <p
        id="s3-8"
        v-html="$t('emailDocument.klasorIslemleri.klasorOlusturma.giris')"
      ></p>
      <p
        id="s3-9"
        v-html="$t('emailDocument.klasorIslemleri.klasorKisayol.giris')"
      ></p>

      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2">
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 24"
            imgPath="emailimages/Resim24.png"
          ></yartu-image>
          <p
            id="s3-10"
            v-html="$t('emailDocument.klasorIslemleri.klasorKisayol.yeni')"
          ></p>
          <p
            id="s3-11"
            v-html="$t('emailDocument.klasorIslemleri.klasorKisayol.okundu')"
          ></p>
          <p
            id="s3-12"
            v-html="$t('emailDocument.klasorIslemleri.klasorKisayol.düzenle')"
          ></p>
          <p
            id="s3-13"
            v-html="$t('emailDocument.klasorIslemleri.klasorKisayol.temizle')"
          ></p>

          <p
            id="s3-14"
            v-html="$t('emailDocument.klasorIslemleri.klasorKisayol.sil')"
          ></p>
          <p
            id="s3-15"
            v-html="
              $t('emailDocument.klasorIslemleri.klasorKisayol.istenmeyen')
            "
          ></p>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 24"
            imgPath="emailimages/Resim24.png"
          ></yartu-image>
        </div>
      </div>
      <contentTitle
        :text="$t('emailDocument.epostaAyarlari.title')"
      ></contentTitle>
      <p
        v-html="
          $t('emailDocument.epostaAyarlari.giris', {
            imgPath: require('../assets/emailimages/iconsbuttons/ayarlar.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 25"
        imgPath="emailimages/Resim26.png"
      ></yartu-image>
      <p id="s4" v-html="$t('emailDocument.epostaAyarlari.giris2')"></p>
      <subtitle-template
        :text="$t('emailDocument.epostaAyarlari.imzaAyarlar.title')"
      >
      </subtitle-template>
      <p
        id="s4-1"
        v-html="
          $t('emailDocument.epostaAyarlari.imzaAyarlar.giris', {
            imgPath1: require('../assets/emailimages/iconsbuttons/epostaimzasi.png'),
            imgPath2: require('../assets/emailimages/iconsbuttons/yeniimzaekle.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 26"
        imgPath="emailimages/Resim27.png"
      ></yartu-image>
      <p
        v-html="
          $t('emailDocument.epostaAyarlari.imzaAyarlar.imzaEkleme', {
            imgPath: require('../assets/emailimages/iconsbuttons/ayarlar.png'),
          })
        "
      ></p>
      <p
        id="s4-2"
        v-html="
          $t('emailDocument.epostaAyarlari.imzaAyarlar.imzaDegisme', {
            imgPath1: require('../assets/emailimages/iconsbuttons/kalembuton.png'),
            imgPath2: require('../assets/emailimages/iconsbuttons/copbuton.png'),
          })
        "
      ></p>

      <yartu-image
        class=""
        imgName="Resim 27"
        imgPath="emailimages/Resim28.png"
      ></yartu-image>
      <subtitle-template
        id="s5"
        :text="$t('emailDocument.epostaAyarlari.kuralAyarlari.title')"
      >
      </subtitle-template>
      <p
        id="s4-3"
        v-html="
          $t('emailDocument.epostaAyarlari.kuralAyarlari.kuralYonetimi', {
            imgPath: require('../assets/emailimages/iconsbuttons/kuralyonetimi.png'),
          })
        "
      ></p>
      <p
        id="s4-4"
        v-html="
          $t('emailDocument.epostaAyarlari.kuralAyarlari.yeniKural', {
            imgPath: require('../assets/emailimages/iconsbuttons/yenikural.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 28"
        imgPath="emailimages/Resim29.png"
      ></yartu-image>
      <p
        id="s4-5"
        v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.tumEpostalar')"
      ></p>
      <div class="px-6 flex flex-col gap-1">
        <p
          v-html="
            $t('emailDocument.epostaAyarlari.kuralAyarlari.kurallariEslestir')
          "
        ></p>
        <p
          v-html="
            $t('emailDocument.epostaAyarlari.kuralAyarlari.kurallariEslestir2')
          "
        ></p>
        <p
          v-html="
            $t('emailDocument.epostaAyarlari.kuralAyarlari.yeniKural', {
              imgPath: require('../assets/emailimages/iconsbuttons/yenikural.png'),
            })
          "
        ></p>
      </div>
      <subtitle-template
        id="s4-6"
        :text="$t('emailDocument.epostaAyarlari.kuralAyarlari.kosultitle')"
      ></subtitle-template>
      <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.kosul')"></p>

      <yartu-image
        class=""
        imgName="Resim 29"
        imgPath="emailimages/Resim30.png"
      >
      </yartu-image>
      <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.alanlar')"></p>
      <div class="px-6 flex flex-col gap-1">
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.konu')"></p>
        <p
          v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.gönderen')"
        ></p>
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.bcc')"></p>
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.cc')"></p>
        <p
          v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.aliciBcc')"
        ></p>
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.govde')"></p>
      </div>
      <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.kosul2')"></p>
      <yartu-image
        class=""
        imgName="Resim 30"
        imgPath="emailimages/Resim31.png"
      >
      </yartu-image>
      <p
        v-html="
          $t('emailDocument.epostaAyarlari.kuralAyarlari.silme', {
            imgPath: require('../assets/emailimages/iconsbuttons/kirmizicop.png'),
          })
        "
      ></p>
      <p
        id="s4-7 "
        v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.eylemler')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 31"
        imgPath="emailimages/Resim32.png"
      >
      </yartu-image>
      <div class="px-6 flex flex-col gap-1">
        <p
          v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.konuyaGore')"
        ></p>
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.sakla')"></p>
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.ilet')"></p>
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.reddet')"></p>
        <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.tası')"></p>
        <p
          v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.kopyala')"
        ></p>
      </div>
      <p v-html="$t('emailDocument.epostaAyarlari.kuralAyarlari.kaydet')"></p>
      <p
        v-html="
          $t('emailDocument.epostaAyarlari.kuralAyarlari.kuralDegistir', {
            imgPath1: require('../assets/emailimages/iconsbuttons/kalembuton.png'),
            imgPath2: require('../assets/emailimages/iconsbuttons/kirmizicop.png'),
          })
        "
      ></p>
    </template>
    <template #drawer>
      <div class="">
        <complate-drawer
          :menuList="menus"
          @clicked="scrollToElement"
        ></complate-drawer>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";

import { defineCustomElement, ref, onMounted, watch } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";
import { useRoute } from "vue-router";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import SentaceWithImage from "../components/SentaceWithImage.vue";
import ComplateDrawer from "@/components/ComplateDrawer.vue";

const openDrawer = ref(false);
const drawerStatus = ref(false);
const route = useRoute();

watch(route, (newroute, oldroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.elementId !== "") {
    console.log("data is", route.Id);
    scrollToElement(route.query.Id);
  }
});
const scrollToElement = (elementId) => {
  console.log(elementId);
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Genel E-Posta",
    elementId: "s1",
    subtitles: [
      {
        title: "E-posta Oluşturma",
        elementId: "s1-1",
      },
      {
        title: "Pencere Görünümü",
        elementId: "s1-2",
      },
      {
        title: "E-posta Gönderme",
        elementId: "s1-3",
      },
    ],
  },
  {
    title: "E-Posta işlemleri",
    elementId: "s2",
    subtitles: [
      {
        title: "E-posta Biçimlendirme",
        elementId: "s2-40",
        content: [
          {
            title: "Yazı Hizalama",
            elementId: "s2-2",
          },
          {
            title: "Arkaplan Rengi",
            elementId: "s2-3",
          },
          {
            title: "Geri, İleri Alma",
            elementId: "s2-4",
          },
          {
            title: "Ek ekleme",
            elementId: "s2-5",
          },
          {
            title: "Resim ekleme",
            elementId: "s2-6",
          },
          {
            title: "Link ve İmza ",
            elementId: "s2-7",
          },
          {
            title: "Template",
            elementId: "s2-8",
          },
        ],
      },
      {
        title: "E-posta İçeriği",
        elementId: "s2-9",
      },
      {
        title: "E-posta Araç Çubuğu",
        elementId: "s2-1",
        content: [
          {
            title: "Okundu",
            elementId: "s2-10",
          },
          {
            title: "Yazdırma",
            elementId: "s2-11",
          },
          {
            title: "Favoriler",
            elementId: "s2-12",
          },
          {
            title: "Klasörleme",
            elementId: "s2-13",
          },
          {
            title: "Yanıtlama",
            elementId: "s2-14",
          },
          {
            title: "İletme ",
            elementId: "s2-15",
          },
          {
            title: "Silme",
            elementId: "s2-16",
          },
        ],
      },
      {
        title: "Ek Listeleme",
        elementId: "s2-17",
      },
      {
        title: "Çoklu Seçim",
        elementId: "s2-18",
        content: [
          {
            title: "Taşıma",
            elementId: "s2-19",
          },
          {
            title: "Favoriler",
            elementId: "s2-20",
          },
          {
            title: "Silme",
            elementId: "s2-21",
          },
        ],
      },
      {
        title: "Sıralama ve Filtreleme",
        elementId: "s2-41",
        content: [
          {
            title: "Filtreleme",
            elementId: "s2-22",
          },
          {
            title: "Sıralama",
            elementId: "s2-23",
          },
        ],
      },
    ],
  },
  {
    title: "Klasör İşlemleri",
    elementId: "s3",
    subtitles: [
      {
        title: "Tanımlı Klasörler",
        elementId: "s2-42",
        content: [
          {
            title: "Gelen Kutusu",
            elementId: "s3-1",
          },
          {
            title: "Yıldızlı",
            elementId: "s3-2",
          },
          {
            title: "Silinenler",
            elementId: "s3-3",
          },
          {
            title: "Arşiv",
            elementId: "s3-4",
          },
          {
            title: "İstenmeyen",
            elementId: "s3-5",
          },
          {
            title: "Tüm Postalar",
            elementId: "s3-6",
          },
          {
            title: "Klasörler",
            elementId: "s3-7",
          },
        ],
      },
      {
        title: "Klasör Oluşturma",
        elementId: "s3-8",
      },
      {
        title: "Klasör Kısayolları",
        elementId: "s3-9",
        content: [
          {
            title: "Yeni Klasör",
            elementId: "s3-10",
          },
          {
            title: "Okundu İşaretleme",
            elementId: "s3-11",
          },
          {
            title: "İsim Düzenleme",
            elementId: "s3-12",
          },
          {
            title: "Klasör Temizleme",
            elementId: "s3-13",
          },
          {
            title: "Klasör Silme",
            elementId: "s3-14",
          },
          {
            title: "Spam",
            elementId: "s3-15",
          },
        ],
      },
    ],
  },
  {
    title: "E-posta Ayarları",
    elementId: "",
    subtitles: [
      {
        title: "İmza Ayarları",
        elementId: "s4",
        content: [
          {
            title: "İmza Ekleme ",
            elementId: "s4-1",
          },
          {
            title: "İmza Değiştirme/Silme",
            elementId: "s4-2",
          },
        ],
      },
      {
        title: "Kural Ayarları",
        elementId: "s5",
        content: [
          {
            title: "Kural Yönetimi ",
            elementId: "s4-3",
          },
          {
            title: "Yeni Kural Ekleme",
            elementId: "s4-4",
          },
          {
            title: "Filtreleme",
            elementId: "s4-5",
          },
          {
            title: "Koşullar",
            elementId: "s4-6",
          },
          {
            title: "Eylemler",
            elementId: "s4-7",
          },
        ],
      },
    ],
  },
]);
</script>
