<template>
  <pagetemplate main-title="Proje" img-path="proje.svg">
    <template #smallDrawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
    <template #content>
      <content-title
        id="s1"
        :text="$t('projectDocument.AnasayfaIslemleri.title')"
      ></content-title>

      <p
        id="s1-1 "
        v-html="
          $t('projectDocument.AnasayfaIslemleri.anasayfa.projeyeErisim', {
            imgPath: require('../assets/icons/proje.svg'),
          })
        "
      ></p>
      <p id="s1-2">
        {{ $t("projectDocument.AnasayfaIslemleri.anasayfa.anaEkranErisim") }}
      </p>
      <yartu-image
        imgName="Resim 1"
        imgPath="yartu_images/Resim1(d).png"
      ></yartu-image>
      <content-title
        id="s1"
        :text="$t('projectDocument.ProjeIslemleri.title')"
      ></content-title>
      <p
        id="s2-1"
        v-html="
          $t(
            'projectDocument.ProjeIslemleri.projeOlusturma.projeOluşturmaContent',
            {
              imgPath: require('../assets/icons/create-new-button.svg'),
            }
          )
        "
      ></p>
      <p
        id="s2-2"
        v-html="
          $t(
            'projectDocument.ProjeIslemleri.projeOzellestirme.projeOzellestirme',
            {
              imgPath: require('../assets/icons/create-button.png'),
            }
          )
        "
      ></p>
      <yartu-image
        imgName="Resim 2"
        imgPath="yartu_images/Resim2(d).png"
      ></yartu-image>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2">
          <p
            id="s2-3"
            v-html="
              $t('projectDocument.ProjeIslemleri.listeleme.content', {
                imgPath: require('../assets/icons/create-button.png'),
              })
            "
          ></p>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 3"
            imgPath="yartu_images/Resim3.svg"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 3"
            imgPath="yartu_images/Resim3.svg"
          ></yartu-image>
        </div>
      </div>

      <p
        id="s2-4"
        v-html="
          $t('projectDocument.ProjeIslemleri.menu.menuContent', {
            imgPath: require('../assets/calendarimages/icons/ucnokta.png'),
          })
        "
      ></p>
      <yartu-image
        imgName="Resim 4"
        imgPath="yartu_images/Resim4.svg"
      ></yartu-image>
      <p id="s2-5">
        {{
          $t("projectDocument.ProjeIslemleri.gereksinimler.panoGereksinimleri")
        }}
      </p>
      <content-title id="s3" text="Pano İşlemleri"></content-title>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2">
          <p id="s3-1">
            {{
              $t("projectDocument.PanoIslemleri.panoOlusturma.panoOlusturma")
            }}
          </p>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 5"
            imgPath="yartu_images/Resim5.svg"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 5"
            imgPath="yartu_images/Resim5.svg"
          ></yartu-image>
        </div>
      </div>

      <p
        id="s3-2"
        v-html="
          $t('projectDocument.PanoIslemleri.panoOzellestirme.panoOzellestirme')
        "
      ></p>
      <yartu-image
        imgName="Resim 6"
        imgPath="yartu_images/Resim6(d).png"
      ></yartu-image>

      <p
        id="s3-3"
        v-html="$t('projectDocument.PanoIslemleri.panoOzellestirme.workflow')"
      ></p>
      <yartu-image
        imgName="Resim 7"
        imgPath="yartu_images/Resim7.svg"
      ></yartu-image>
      <p
        id="s3-4"
        v-html="$t('projectDocument.PanoIslemleri.panoOzellestirme.proje')"
      ></p>

      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2">
          <p
            id="s3-5"
            v-html="
              $t('projectDocument.PanoIslemleri.panoOzellestirme.kullanicilar')
            "
          ></p>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 9"
            imgPath="yartu_images/Resim9.svg"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 9"
            imgPath="yartu_images/Resim9.svg"
          ></yartu-image>
        </div>
      </div>
      <p
        id="s3-6"
        v-html="
          $t('projectDocument.PanoIslemleri.panoOzellestirme.roller', {
            imgPath: require('../assets/driveimages/iconsbuttons/ucnokta.png'),
          })
        "
      ></p>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2">
          <p
            id="s3-6-1"
            v-html="
              $t('projectDocument.PanoIslemleri.panoOzellestirme.kullanici')
            "
          ></p>
          <p
            id="s3-6-2"
            v-html="
              $t('projectDocument.PanoIslemleri.panoOzellestirme.yönetici')
            "
          ></p>
          <p
            id="s3-6-3"
            v-html="
              $t('projectDocument.PanoIslemleri.panoOzellestirme.misafir')
            "
          ></p>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 10"
            imgPath="yartu_images/Resim10.svg"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 10"
            imgPath="yartu_images/Resim10.svg"
          ></yartu-image>
        </div>
      </div>

      <p
        id="s3-7"
        v-html="
          $t('projectDocument.PanoIslemleri.panoOzellestirme.panoListesi')
        "
      ></p>
      <yartu-image
        imgName="Resim 11/1"
        imgPath="yartu_images/Resim11-1.svg"
      ></yartu-image>
      <yartu-image
        imgName="Resim 11/2"
        imgPath="yartu_images/Resim11-2.svg"
      ></yartu-image>
      <h1
        id="s3-8"
        class="font-semibold text-lg py-4"
        v-html="$t('projectDocument.PanoIslemleri.panoAnaSayfa.title')"
      ></h1>
      <p
        id="s3-8-1"
        v-html="$t('projectDocument.PanoIslemleri.panoAnaSayfa.panoAnaSayfa1')"
      ></p>
      <yartu-image
        imgName="Resim 12"
        imgPath="yartu_images/Resim12.svg"
      ></yartu-image>
      <p
        id="s3-8-2"
        v-html="$t('projectDocument.PanoIslemleri.panoAnaSayfa.panoAnaSayfa2')"
      ></p>
      <div class="px-6">
        <span
          class="flex flex-row items-start py-2"
          v-html="
            $t('projectDocument.PanoIslemleri.panoAraclari.favoriler', {
              imgPath: require('../assets/icons/yıldız.svg'),
            })
          "
        >
        </span>
        <span class="flex flex-row items-start py-2">
          <y-icon class="pr-1 pt-1 flex" name="yi yi-info"> </y-icon>
          <p v-html="$t('projectDocument.PanoIslemleri.panoAraclari.info')"></p>
        </span>
        <yartu-image
          class="w-[250px] mx-auto"
          imgName="Resim 13"
          imgPath="yartu_images/Resim13(d).png"
        ></yartu-image>
        <p
          id="s3-8-3"
          v-html="
            $t('projectDocument.PanoIslemleri.panoAraclari.panoAraclari', {
              imgPath: require('../assets/driveimages/iconsbuttons/ucnokta.png'),
            })
          "
        ></p>
        <yartu-image
          class="w-[200px] mx-auto"
          imgName="Resim 14"
          imgPath="yartu_images/Resim14(d).png"
        ></yartu-image>
        <p
          v-html="
            $t('projectDocument.PanoIslemleri.panoAraclari.etiketleriYonet')
          "
        ></p>
        <yartu-image
          imgName="Resim 15"
          imgPath="yartu_images/Resim15.svg"
        ></yartu-image>
        <span class="flex flex-row items-start py-2">
          <y-icon class="pr-1 pt-1 flex" name="yi yi-rename"> </y-icon>
          <p
            v-html="
              $t('projectDocument.PanoIslemleri.panoAraclari.yenidenAdlandir')
            "
          ></p>
        </span>
        <span class="flex flex-row items-start py-2">
          <y-icon class="pr-1 pt-1 flex" name="yi yi-trash"> </y-icon>
          <p
            v-html="$t('projectDocument.PanoIslemleri.panoAraclari.silme')"
          ></p>
        </span>
        <span class="flex flex-row items-start py-2">
          <img class="pr-1" src="../assets/icons/createNew.svg" />
          <p
            v-html="$t('projectDocument.PanoIslemleri.panoAraclari.yeniEtiket')"
          ></p>
        </span>
        <p
          class="py-2"
          v-html="
            $t('projectDocument.PanoIslemleri.panoAraclari.saveAsWorkflow')
          "
        ></p>
        <p
          class="py-2"
          v-html="
            $t('projectDocument.PanoIslemleri.panoAraclari.panoLinkiKopyala')
          "
        ></p>
        <p
          class="py-2"
          v-html="$t('projectDocument.PanoIslemleri.panoAraclari.panoyuEsle')"
        ></p>
        <p
          class="py-2"
          v-html="
            $t('projectDocument.PanoIslemleri.panoAraclari.panoyuKopyala')
          "
        ></p>
        <yartu-image
          class="w-[300px] mx-auto"
          imgName="Resim 16"
          imgPath="yartu_images/Resim16(d).png"
        ></yartu-image>
        <p
          class="py-2"
          v-html="
            $t('projectDocument.PanoIslemleri.panoAraclari.panoyuKopyala2')
          "
        ></p>
        <p
          class="py-2"
          v-html="$t('projectDocument.PanoIslemleri.panoAraclari.panoyuTasi')"
        ></p>
        <yartu-image
          imgName="yartu_images/Resim 17"
          imgPath="yartu_images/Resim17.svg"
        ></yartu-image>
        <p
          class="py-2"
          v-html="$t('projectDocument.PanoIslemleri.panoAraclari.panoyuTasi2')"
        ></p>
        <p
          class="py-2"
          v-html="$t('projectDocument.PanoIslemleri.panoAraclari.arsiv')"
        ></p>
        <yartu-image
          imgName="yartu_images/Resim 18"
          imgPath="yartu_images/Resim18.svg"
        ></yartu-image>
        <span class="flex flex-row items-start py-2">
          <y-icon class="pr-1" name="yi yi-unarchive"> </y-icon>
          <p
            v-html="
              $t('projectDocument.PanoIslemleri.panoAraclari.arsivdenCikarma')
            "
          ></p>
        </span>
        <span class="flex flex-row items-start py-2">
          <y-icon class="pr-1" name="yi yi-delete"> </y-icon>
          <p
            v-html="
              $t('projectDocument.PanoIslemleri.panoAraclari.arsivdenSilme')
            "
          ></p>
        </span>
        <div class="flex flex-row gap-6 mx-auto">
          <div class="flex flex-col justify-center items-start gap-2">
            <p
              class="py-2"
              v-html="
                $t('projectDocument.PanoIslemleri.panoAraclari.panoyuSil')
              "
            ></p>
            <yartu-image
              class="w-full xs:hidden"
              imgName="Resim 10"
              imgPath="yartu_images/Resim19.svg"
            ></yartu-image>
          </div>
          <div class="xs:block hidden w-full">
            <yartu-image
              class=""
              imgName="Resim 19"
              imgPath="yartu_images/Resim19.svg"
            ></yartu-image>
          </div>
        </div>
        <div class="flex flex-row gap-6 mx-auto">
          <div class="flex flex-col justify-center items-start gap-2">
            <p
              class="py-2"
              v-html="$t('projectDocument.PanoIslemleri.kullaniciEkleme')"
            ></p>
            <p
              class="py-2"
              id="s3-12"
              v-html="
                $t(
                  'projectDocument.PanoIslemleri.yetkiDuzenleme.yetkiBelirleme'
                )
              "
            ></p>
            <p
              class="py-2"
              v-html="
                $t(
                  'projectDocument.PanoIslemleri.yetkiDuzenleme.yetkiBelirleme2'
                )
              "
            ></p>
            <yartu-image
              class="w-full xs:hidden"
              imgName="Resim 20"
              imgPath="yartu_images/Resim20.svg"
            ></yartu-image>
          </div>
          <div class="xs:block hidden w-full">
            <yartu-image
              class=""
              imgName="Resim 20"
              imgPath="yartu_images/Resim20.svg"
            ></yartu-image>
          </div>
        </div>

        <div class="flex flex-row gap-6 mx-auto">
          <div class="flex flex-col justify-center items-start gap-2">
            <p
              id="s3-13 "
              class="py-2"
              v-html="
                $t(
                  'projectDocument.PanoIslemleri.yetkiDuzenleme.yetkiBelirleme3'
                )
              "
            ></p>

            <yartu-image
              class="w-full xs:hidden"
              imgName="Resim 21"
              imgPath="yartu_images/Resim21.svg"
            ></yartu-image>
          </div>
          <div class="xs:block hidden w-full">
            <yartu-image
              class=""
              imgName="Resim 21"
              imgPath="yartu_images/Resim21.svg"
            ></yartu-image>
          </div>
        </div>

        <sentace-with-image
          id="s2-20"
          img-path="icons/yuvarlakCarp.svg"
          :text="
            $t('projectDocument.PanoIslemleri.yetkiDuzenleme.kullaniciCikarma')
          "
        >
        </sentace-with-image>
        <p
          id="s3-14"
          v-html="$t('projectDocument.PanoIslemleri.kartFiltreleme.giris')"
        ></p>
      </div>

      <yartu-image
        class="w-[275px] mx-auto"
        imgName="Resim 22"
        imgPath="yartu_images/Resim22.svg"
      ></yartu-image>
      <div class="flex flex-col gap-2 px-6">
        <p
          id="s3-14"
          v-html="$t('projectDocument.PanoIslemleri.kartFiltreleme.gorev')"
        ></p>
        <div class="flex flex-col gap-2 px-4">
          <p
            id="s3-14"
            v-html="
              $t('projectDocument.PanoIslemleri.kartFiltreleme.bitmemisGorev')
            "
          ></p>

          <p
            id="s3-14"
            v-html="
              $t('projectDocument.PanoIslemleri.kartFiltreleme.bitmisGorev')
            "
          ></p>
        </div>
        <p
          id="s3-14"
          v-html="$t('projectDocument.PanoIslemleri.kartFiltreleme.uye')"
        ></p>
        <p
          id="s3-14"
          v-html="$t('projectDocument.PanoIslemleri.kartFiltreleme.etiket')"
        ></p>
        <p
          id="s3-14"
          v-html="
            $t('projectDocument.PanoIslemleri.kartFiltreleme.bitisTarihi')
          "
        ></p>
      </div>
      <subtitle-template
        :text="$t('projectDocument.PanoIslemleri.isListeleri.title')"
      >
      </subtitle-template>
      <p
        id="s3-9-1"
        v-html="$t('projectDocument.PanoIslemleri.isListeleri.panoIcerigi')"
      ></p>
      <p
        id="s3-9-2"
        v-html="
          $t('projectDocument.PanoIslemleri.varsayilanPano.varsayilanSutunlar')
        "
      ></p>
      <yartu-image
        imgName="Resim 23"
        imgPath="yartu_images/Resim23.svg"
      ></yartu-image>
      <p
        id="s3-9-3"
        v-html="$t('projectDocument.PanoIslemleri.sutunEkleme.sutunEkleme')"
      ></p>
      <p
        id="s3-9-4"
        v-html="
          $t('projectDocument.PanoIslemleri.sutunEkleme.sutunEkleme2', {
            imgPath1: require('../assets/icons/add-column-button.svg'),
            imgPath2: require('../assets/icons/save_button.png'),
          })
        "
      ></p>
      <yartu-image
        imgName="Resim 24"
        imgPath="yartu_images/Resim24.svg"
      ></yartu-image>
      <p
        id="s3-9-5"
        v-html="$t('projectDocument.PanoIslemleri.sutunEkleme.sutunEkleme3')"
      ></p>
      <h1
        id="s3-10"
        class="font-semibold text-lg py-4"
        v-html="$t('projectDocument.Kart.kartTitle')"
      ></h1>
      <p
        id="s10-1"
        v-html="$t('projectDocument.Kart.kartEkleme.kartEkleme')"
      ></p>
      <p
        id="s3-10-2"
        v-html="
          $t('projectDocument.Kart.kartEkleme2', {
            imgPath: require('../assets/icons/add-task-button.svg'),
          })
        "
      ></p>
      <p
        id="s10-2"
        v-html="
          $t('projectDocument.Kart.kartIslemleri.kartIslemleri', {
            imgPath: require('../assets/calendarimages/icons/ucnokta.png'),
          })
        "
      ></p>
      <div class="flex flex-row gap-6">
        <div class="flex flex-col justify-center items-start gap-2">
          <p
            v-html="$t('projectDocument.Kart.kartIslemleri.kartIslemleri2')"
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 20"
            imgPath="yartu_images/Resim20.svg"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-2/5">
          <yartu-image
            class=""
            imgName="Resim 25"
            imgPath="yartu_images/Resim25.svg"
          ></yartu-image>
        </div>
      </div>

      <p
        id="s3-10 "
        v-html="$t('projectDocument.Kart.kartDetayEkrani.kartDetay')"
      ></p>
      <yartu-image
        imgName="Resim 26"
        imgPath="yartu_images/Resim26.svg"
      ></yartu-image>
      <p v-html="$t('projectDocument.Kart.kartDetayEkrani.kartDetay2')"></p>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2">
          <sentace-with-image
            img-path="icons/backlog_button.svg"
            :text="$t('projectDocument.Kart.kartDetayEkrani.backlog')"
          >
          </sentace-with-image>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 27"
            imgPath="yartu_images/Resim27.svg"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 27"
            imgPath="yartu_images/Resim27.svg"
          ></yartu-image>
        </div>
      </div>

      <div class="px-4 flex flex-col gap-2">
        <sentace-with-image
          id="s10-4"
          img-path="icons/matkComplate.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.tamamlandi')"
        >
        </sentace-with-image>
        <sentace-with-image
          img-path="icons/join.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.katil')"
        >
        </sentace-with-image>
        <sentace-with-image
          id="s10-5"
          img-path="icons/palette.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.palet')"
        >
        </sentace-with-image>

        <sentace-with-image
          img-path="icons/attach.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.dosyalar')"
        >
        </sentace-with-image>

        <div class="flex flex-row gap-6 mx-auto">
          <div class="flex flex-col justify-center items-start gap-2">
            <sentace-with-image
              id="s10-6"
              img-path="icons/assignee.svg"
              :text="
                $t('projectDocument.Kart.kartDetayEkrani.kartaKullaniciEkleme')
              "
            >
            </sentace-with-image>

            <yartu-image
              class="w-full xs:hidden"
              imgName="Resim 28"
              imgPath="yartu_images/Resim28.svg"
            ></yartu-image>
          </div>
          <div class="xs:block hidden w-full">
            <yartu-image
              class=""
              imgName="Resim 28"
              imgPath="yartu_images/Resim28.svg"
            ></yartu-image>
          </div>
        </div>
        <sentace-with-image
          id="s10-7"
          img-path="icons/deadline.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.bitisTarihi')"
        >
        </sentace-with-image>
        <sentace-with-image
          img-path="icons/noDeadline.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.gecerliBitisTarihi')"
        >
        </sentace-with-image>

        <yartu-image
          class="mx-auto w-[250px]"
          imgName="Resim 29"
          imgPath="yartu_images/Resim29(d).png"
        ></yartu-image>
        <sentace-with-image
          id="s10-8"
          img-path="icons/description.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.aciklama')"
        >
        </sentace-with-image>
        <sentace-with-image
          id="s10-9"
          img-path="icons/attachment.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.dosyaEkleme')"
        >
        </sentace-with-image>
        <yartu-image
          class=""
          imgName="Resim 30"
          imgPath="yartu_images/Resim30.svg"
        ></yartu-image>
        <sentace-with-image
          id="s10-10"
          img-path="icons/checklist.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.yapilacaklar')"
        >
        </sentace-with-image>
        <sentace-with-image
          img-path="icons/newChecklist.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.yapilacaklarIsim')"
        >
        </sentace-with-image>
        <sentace-with-image
          img-path="icons/addChecklist.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.yapilacaklarKisiAta')"
        >
        </sentace-with-image>
        <yartu-image
          id="s10-11"
          class=""
          imgName="Resim 31"
          imgPath="yartu_images/Resim31.svg"
        ></yartu-image>
        <sentace-with-image
          img-path="icons/person.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.kisiAtama')"
        >
        </sentace-with-image>
        <sentace-with-image
          img-path="icons/flag.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.bitisTarihiEkle')"
        >
        </sentace-with-image>
        <sentace-with-image
          img-path="icons/etiket.svg"
          :text="$t('projectDocument.Kart.kartDetayEkrani.etiketEkle')"
        >
        </sentace-with-image>
      </div>
    </template>
    <template #drawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";
import router from "@/router";
import { onMounted, ref, watch } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";
import YartuAccordion from "@/components/YartuAccordion.vue";
import DrawerItem from "../components/DrawerItem.vue";
import SentaceWithImage from "@/components/SentaceWithImage.vue";
import ComplateDrawer from "@/components/ComplateDrawer.vue";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import { onBeforeUnmount, onBeforeRouteEnter, useRoute } from "vue-router";

const openDrawer = ref(false);
const drawerStatus = ref(false);

const route = useRoute();

const scrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};
watch(route, (newroute, oldroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.elementId !== "") {
    console.log("data is", route.Id);
    scrollToElement(route.query.Id);
  }
});

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Anasayfa İşlemleri",
    elementId: "s1",
    subtitles: [
      {
        title: "Projeye Erişim",
        elementId: "s1-1",
      },
      {
        title: "Ana Ekran Erişim",
        elementId: "s1-2",
      },
    ],
  },
  {
    title: "Proje İşlemleri",
    elementId: "s2",
    subtitles: [
      {
        title: "Proje Oluşturma",
        elementId: "s2-1",
      },
      {
        title: "Proje Özelleştirme",
        elementId: "s2-2",
      },
      {
        title: "Proje Listeleme",
        elementId: "s2-3",
      },
      {
        title: "Menu Seçenekleri",
        elementId: "s2-4",
      },
      {
        title: "Pano Gereksinimleri",
        elementId: "s2-5",
      },
    ],
  },
  {
    title: "Pano İşlemleri",
    elementId: "s3",
    subtitles: [
      {
        title: "Pano Oluşturma",
        elementId: "s3-1",
      },
      {
        title: "Pano Özelleştirme",
        elementId: "s3-2",
        content: [
          {
            title: "Workflow",
            elementId: "s3-3",
          },
          {
            title: "Proje",
            elementId: "s3-4",
          },
          {
            title: "Kullanıcılar",
            elementId: "s3-5",
          },
          {
            title: "Roller",
            elementId: "s3-6",
          },
          {
            title: "Pano Listesi",
            elementId: "s3-7",
          },
        ],
      },

      {
        title: "Pano Ana Sayfa",
        elementId: "s3-8",
      },
      {
        title: "Pano Araçları",
        elementId: "s3-8-2",
        content: [
          {
            title: "Favoriler",
            elementId: "s8-1",
          },
          {
            title: "Pano Detayları",
            elementId: "s8-2",
          },
          {
            title: "Etiket Yönetimi",
            elementId: "s8-3",
          },
          {
            title: "Etiket Düzenleme",
            elementId: "s8-4",
          },
          {
            title: "Etiket Silme",
            elementId: "s8-5",
          },
          {
            title: "Etiket Ekleme",
            elementId: "s8-6",
          },
          {
            title: "Taslak Kaydetme",
            elementId: "s8-7",
          },
          {
            title: "Panoyu Kopyala",
            elementId: "s8-8",
          },
          {
            title: "Pano Taşıma",
            elementId: "s8-9",
          },
          {
            title: "Pano Silme",
            elementId: "s8-10",
          },
        ],
      },
      {
        title: "Kullanıcı Daveti",
        elementId: "s3-11",
      },
      {
        title: "Yetki Düzenleme",
        elementId: "s3-12",
      },

      {
        title: "İş Listeleri (Sütunlar)",
        elementId: "s3-9",
      },
      {
        title: "Varsayılan Pano Düzeni",
        elementId: "s3-9-2",
      },
      {
        title: "Sütun Ekleme İşlemi",
        elementId: "s3-9-3",
      },
    ],
  },
  {
    title: "Kart",
    elementId: "s3-10",
    subtitles: [
      {
        title: "Kart Ekleme",
        elementId: "s10-1",
      },
      {
        title: "Kart İşlemleri",
        elementId: "s10-2",
      },
      {
        title: "Kart Detay Ekranı",
        elementId: "s10-3",
        content: [
          {
            title: "Tamamlandı İşaretleme",
            elementId: "s10-4",
          },
          {
            title: "Karttaki Dosya Sayısı",
            elementId: "s10-5",
          },
          {
            title: "Karta İş Ekleme",
            elementId: "s10-6",
          },
          {
            title: "Bitiş Tarihi Ekleme",
            elementId: "s10-7",
          },
          {
            title: "Açıklama Ekleme",
            elementId: "s10-8",
          },
          {
            title: "Dosya Ekleme",
            elementId: "s10-9",
          },
          {
            title: "Yapılacaklar Listesi",
            elementId: "s10-10",
          },
          {
            title: "Yapılacaklar Listesi Araçları",
            elementId: "s10-11",
          },
        ],
      },
    ],
  },
]);
</script>
<script></script>
