<template>
  <pagetemplate main-title="Drive" img-path="depolama.svg">
    <template #smallDrawer>
      <div>
        <complate-drawer
          :menuList="menus"
          @clicked="scrollToElement"
        ></complate-drawer>
      </div>
    </template>
    <template #content>
      <content-title :text="$t('storageDocument.title')"></content-title>

      <div class="flex flex-row gap-6 mx-auto">
        <div id="s2-23" class="flex flex-col justify-center items-start gap-2">
          <p
            id="s1-1"
            v-html="
              $t(
                'storageDocument.DriveAnaSayfa.dosyaYonetim.dosyaYönetimMenusu'
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 1"
            imgPath="driveimages/Resim1.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 1"
            imgPath="driveimages/Resim1.png"
          ></yartu-image>
        </div>
      </div>

      <div class="flex flex-row gap-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p
            id="s1-2"
            v-html="
              $t(
                'storageDocument.DriveAnaSayfa.anaSayfaButonlari.anaSayfaButonları'
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 2"
            imgPath="driveimages/Resim2.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden">
          <yartu-image
            class=""
            imgName="Resim 2"
            imgPath="driveimages/Resim2.png"
          ></yartu-image>
        </div>
      </div>
      <p
        v-html="
          $t('storageDocument.DriveAnaSayfa.anaSayfaButonlari.yukleButonu')
        "
      ></p>
      <subtitle-template
        id="s2-1"
        :text="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.title')"
      >
      </subtitle-template>
      <p
        v-html="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.dosyaYukleme')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 3"
        imgPath="driveimages/Resim3.png"
      ></yartu-image>
      <p
        v-html="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.varsayilanDepo')"
      ></p>
      <p
        id="s1-3"
        v-html="
          $t('storageDocument.DriveAnaSayfa.dosyaYukleme.yeniDepoOlusturma', {
            imgPath: require('../assets/driveimages/iconsbuttons/createnewrepo.png'),
          })
        "
      ></p>
      <div class="p-4 border-2 rounded-xl">
        <p
          class="italic text-sm"
          v-html="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.parolaliDepo')"
        ></p>
      </div>
      <yartu-image
        id="s2-1"
        class=""
        imgName="Resim 4"
        imgPath="driveimages/Resim4.png"
      ></yartu-image>
      <p
        id="s2-2"
        v-html="
          $t('storageDocument.DriveAnaSayfa.dosyaYukleme.yeniKlasör', {
            imgPath: require('../assets/driveimages/iconsbuttons/createnewfolder.png'),
          })
        "
      ></p>

      <yartu-image
        class=""
        imgName="Resim 5"
        imgPath="driveimages/Resim5.png"
      ></yartu-image>
      <p
        id="s2-7"
        v-html="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.yeniKlasör2')"
      ></p>
      <div class="p-4 border-2 rounded-xl">
        <p
          class="italic text-sm"
          v-html="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.depoNot')"
        ></p>
      </div>
      <p
        id="s2-3"
        v-html="
          $t('storageDocument.DriveAnaSayfa.dosyaYukleme.dosyaYuklemePenceresi')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 6"
        imgPath="driveimages/Resim6.png"
      ></yartu-image>
      <p
        v-html="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.dosyaSecmi')"
      ></p>

      <div class="flex flex-row gap-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p
            id="s2-4"
            v-html="
              $t(
                'storageDocument.DriveAnaSayfa.dosyaYukleme.adresDegisikligi',
                {
                  imgPath: require('../assets/driveimages/iconsbuttons/yukariok.png'),
                }
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 7"
            imgPath="driveimages/Resim7.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 7"
            imgPath="driveimages/Resim7.png"
          ></yartu-image>
        </div>
      </div>
      <p
        id="s2-5 "
        v-html="$t('storageDocument.DriveAnaSayfa.dosyaYukleme.yuklemeDetay')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 8"
        imgPath="driveimages/Resim8.png"
      ></yartu-image>
      <subtitle-template
        id="s3-1"
        :text="$t('storageDocument.DriveAnaSayfa.klasorYukleme.title')"
      ></subtitle-template>
      <p
        v-html="$t('storageDocument.DriveAnaSayfa.klasorYukleme.klasorYukleme')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 9"
        imgPath="driveimages/Resim9.png"
      ></yartu-image>
      <p
        v-html="
          $t('storageDocument.DriveAnaSayfa.klasorYukleme.varsayilanDepo')
        "
      ></p>
      <p
        id="s3-6"
        v-html="
          $t('storageDocument.DriveAnaSayfa.klasorYukleme.yeniDepoOlusturma', {
            imgPath: require('../assets/driveimages/iconsbuttons/createnewrepo.png'),
          })
        "
      ></p>
      <div class="p-4 border-2 rounded-xl">
        <p
          class="italic text-sm"
          v-html="$t('storageDocument.DriveAnaSayfa.klasorYukleme.klasorNot')"
        ></p>
      </div>
      <yartu-image
        class=""
        imgName="Resim 10"
        imgPath="driveimages/Resim4.png"
      ></yartu-image>
      <p
        id="s3-2"
        v-html="
          $t('storageDocument.DriveAnaSayfa.klasorYukleme.yeniKlasor', {
            imgPath: require('../assets/driveimages/iconsbuttons/yeni_klasor.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 11"
        imgPath="driveimages/Resim5.png"
      ></yartu-image>
      <p
        id="s3-7"
        v-html="$t('storageDocument.DriveAnaSayfa.klasorYukleme.yeniKlasor2')"
      ></p>
      <div class="p-4 border-2 rounded-xl">
        <p
          class="italic text-sm"
          v-html="$t('storageDocument.DriveAnaSayfa.klasorYukleme.depoNot')"
        ></p>
      </div>
      <p
        id="s3-3"
        v-html="
          $t(
            'storageDocument.DriveAnaSayfa.klasorYukleme.dosyaYuklemePenceresi'
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 12"
        imgPath="driveimages/Resim12.png"
      ></yartu-image>
      <p
        v-html="$t('storageDocument.DriveAnaSayfa.klasorYukleme.dosyaSecmi')"
      ></p>

      <div class="flex flex-row gap-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p
            id="s3-4"
            v-html="
              $t(
                'storageDocument.DriveAnaSayfa.klasorYukleme.adresDegisikligi',
                {
                  imgPath: require('../assets/driveimages/iconsbuttons/yukariok.png'),
                }
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 13"
            imgPath="driveimages/Resim7.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 13"
            imgPath="driveimages/Resim13.png"
          ></yartu-image>
        </div>
      </div>
      <p v-html="$t('storageDocument.DriveAnaSayfa.klasorYukleme.uyari')"></p>
      <yartu-image
        class=""
        imgName="Resim 14"
        imgPath="driveimages/Resim14.png"
      ></yartu-image>
      <p
        id="s3-5"
        v-html="$t('storageDocument.DriveAnaSayfa.klasorYukleme.yuklemeDetay')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 15"
        imgPath="driveimages/Resim8.png"
      ></yartu-image>
      <p
        id="depo"
        v-html="
          $t(
            'storageDocument.DriveAnaSayfa.sifreliDepoOlustuma.olusturButonu',
            {
              imgPath: require('../assets/driveimages/iconsbuttons/repository.png'),
            }
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 16"
        imgPath="driveimages/Resim4.png"
      ></yartu-image>
      <p
        v-html="
          $t('storageDocument.DriveAnaSayfa.sifreliDepoOlustuma.olusturButonu2')
        "
      ></p>
      <p
        id="s4"
        v-html="
          $t(
            'storageDocument.DriveAnaSayfa.sifreliDepoOlustuma.parolaliDepoOlusturma'
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 17"
        imgPath="driveimages/Resim17.png"
      ></yartu-image>

      <div class="flex flex-row gap-6 mx-auto">
        <div id="s2-23" class="flex flex-col justify-center items-start gap-2">
          <p
            v-html="
              $t(
                'storageDocument.DriveAnaSayfa.sifreliDepoOlustuma.parolaliDepoListele'
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 18"
            imgPath="driveimages/Resim18.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-3/5">
          <yartu-image
            class=""
            imgName="Resim 18"
            imgPath="driveimages/Resim18.png"
          ></yartu-image>
        </div>
      </div>
      <div class="flex flex-row gap-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p
            v-html="
              $t(
                'storageDocument.DriveAnaSayfa.sifreliDepoOlustuma.parolaIslemi'
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 19"
            imgPath="driveimages/Resim19.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden">
          <yartu-image
            class=""
            imgName="Resim 19"
            imgPath="driveimages/Resim19.png"
          ></yartu-image>
        </div>
      </div>
      <div class="p-4 border-2 rounded-xl">
        <p
          class="italic text-sm"
          v-html="
            $t('storageDocument.DriveAnaSayfa.sifreliDepoOlustuma.parolaNot')
          "
        ></p>
      </div>
      <p
        v-html="
          $t('storageDocument.DriveAnaSayfa.sifreliDepoOlustuma.anaEkran', {
            imgPath: require('../assets/driveimages/iconsbuttons/asagiok.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 20"
        imgPath="driveimages/Resim20.png"
      ></yartu-image>
      <content-title :text="$t('storageDocument.Depom.title')"></content-title>
      <p
        id="s5-1"
        v-html="
          $t('storageDocument.Depom.sayfayaErisim.erisim', {
            imgPath: require('../assets/driveimages/iconsbuttons/mydrive.png'),
          })
        "
      ></p>
      <p v-html="$t('storageDocument.Depom.sayfayaErisim.erisim2')"></p>
      <div class="flex flex-row gap-6 py-4 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <div id="s5-1">
            <span class="font-semibold">{{
              $t("storageDocument.Depom.yukleme.yukle")
            }}</span>
            {{ $t("storageDocument.Depom.yukleme.yukle1") }}
            <span
              @click="scrollToElement('s2-1')"
              class="text-blue-700 inline cursor-pointer"
              >{{ $t("storageDocument.Depom.yukleme.yukle2") }}</span
            >
            yada
            <span
              @click="scrollToElement('s3-1')"
              class="text-blue-700 inline cursor-pointer"
              >{{ $t("storageDocument.Depom.yukleme.yukle3") }}</span
            >
            {{ $t("storageDocument.Depom.yukleme.yukle4") }}
          </div>
          <p id="s5-2" v-html="$t('storageDocument.Depom.yukleme.olustur')"></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 21"
            imgPath="driveimages/Resim21.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 21"
            imgPath="driveimages/Resim21.png"
          ></yartu-image>
        </div>
      </div>

      <yartu-image
        class=""
        imgName="Resim 23"
        imgPath="driveimages/Resim23.png"
      ></yartu-image>
      <p id="s6-2">
        <span v-html="$t('storageDocument.Depom.yeniOlusturma.depo1')"> </span>

        <span
          @click="scrollToElement('depo')"
          class="text-blue-700 inline cursor-pointer"
          >{{ $t("storageDocument.Depom.yeniOlusturma.depo2") }}</span
        >
        {{ $t("storageDocument.Depom.yeniOlusturma.depo3") }}
      </p>

      <p
        id="s6-3"
        v-html="
          $t('storageDocument.Depom.yeniOlusturma.dosya', {
            imgPath: require('../assets/driveimages/iconsbuttons/folder.png'),
          })
        "
      ></p>
      <p
        id="s6-4"
        v-html="
          $t('storageDocument.Depom.yeniOlusturma.dokuman', {
            imgPath: require('../assets/driveimages/iconsbuttons/yartudocument.png'),
          })
        "
      ></p>
      <p
        id="s6-5"
        v-html="
          $t('storageDocument.Depom.yeniOlusturma.hesapTablosu', {
            imgPath: require('../assets/driveimages/iconsbuttons/yartusheet.png'),
          })
        "
      ></p>
      <p
        id="s6-6"
        v-html="
          $t('storageDocument.Depom.yeniOlusturma.sunumDosyası', {
            imgPath: require('../assets/driveimages/iconsbuttons/yartupresentations.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 22"
        imgPath="driveimages/Resim22.png"
      ></yartu-image>
      <p v-html="$t('storageDocument.Depom.aracCubugu.dosyaSayisi')"></p>
      <sentace-with-image
        id="s7-2"
        img-path="driveimages/iconsbuttons/donenok.png"
        text=": Depo içeriğini yenilemek için kullanılır. "
      ></sentace-with-image>

      <div class="flex flex-row">
        <div class="flex justify-end items-start">
          <img src="../assets/driveimages/iconsbuttons/artıperson.png" />
          <y-icon
            class="p-2"
            v-if="iconname !== ''"
            :name="[iconname]"
          ></y-icon>
        </div>
        <p id="s7-3" class="">
          {{ $t("storageDocument.Depom.aracCubugu.paylasim1") }}
          <span
            @click="scrollToElement('s8-4')"
            class="text-blue-700 inline-block cursor-pointer"
            >{{ $t("storageDocument.Depom.aracCubugu.paylasim2") }}</span
          >
          {{ $t("storageDocument.Depom.aracCubugu.paylasim3") }}
        </p>
      </div>
      <sentace-with-image
        id="s7-4"
        img-path="driveimages/iconsbuttons/depo.png"
        :text="$t('storageDocument.Depom.aracCubugu.gorunum')"
      ></sentace-with-image>
      <yartu-image
        class=""
        imgName="Resim 24"
        imgPath="driveimages/Resim24.png"
      ></yartu-image>
      <yartu-image
        class=""
        imgName="Resim 25"
        imgPath="driveimages/Resim25.png"
      ></yartu-image>
      <sentace-with-image
        id="s7-5"
        img-path="driveimages/iconsbuttons/info.png"
        :text="$t('storageDocument.Depom.aracCubugu.bilgi')"
      ></sentace-with-image>

      <yartu-image
        class=""
        imgName="Resim 26"
        imgPath="driveimages/Resim26.png"
      ></yartu-image>
      <p
        id="s7-6"
        v-html="
          $t('storageDocument.Depom.aracCubugu.ekIslem', {
            imgPath: require('../assets/driveimages/iconsbuttons/ucnokta.png'),
          })
        "
      ></p>
      <p v-html="$t('storageDocument.Depom.aracCubugu.copKutusu')"></p>
      <p v-html="$t('storageDocument.Depom.aracCubugu.gecmis')"></p>
      <yartu-image
        class=""
        imgName="Resim 27"
        imgPath="driveimages/Resim27.png"
      ></yartu-image>
      <p v-html="$t('storageDocument.Depom.aracCubugu.sil')"></p>
      <p id="s5-3" v-html="$t('storageDocument.Depom.listeleme.content')"></p>
      <yartu-image
        class=""
        imgName="Resim 28"
        imgPath="driveimages/Resim28.png"
      ></yartu-image>
      <subtitle-template
        :text="$t('storageDocument.Depom.tekliKlasor.title')"
      ></subtitle-template>
      <p v-html="$t('storageDocument.Depom.tekliKlasor.giris')"></p>
      <p id="s8-2" v-html="$t('storageDocument.Depom.tekliKlasor.menu')"></p>

      <div class="flex flex-row gap-6 py-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p id="s8-3" v-html="$t('storageDocument.Depom.tekliKlasor.gir')"></p>
          <p
            id="s8-4"
            v-html="$t('storageDocument.Depom.tekliKlasor.paylas')"
          ></p>
          <p
            id="s8-5"
            v-html="$t('storageDocument.Depom.tekliKlasor.linklePaylas')"
          ></p>
          <p
            id="s8-6"
            v-html="$t('storageDocument.Depom.tekliKlasor.indir')"
          ></p>
          <p
            id="s8-7"
            v-html="$t('storageDocument.Depom.tekliKlasor.epostaIleGonder')"
          ></p>
          <p
            id="s8-8"
            v-html="$t('storageDocument.Depom.tekliKlasor.yenidenAdlandir')"
          ></p>
          <p
            id="s8-9"
            v-html="$t('storageDocument.Depom.tekliKlasor.surayaTası')"
          ></p>
          <p
            id="s8-10"
            v-html="$t('storageDocument.Depom.tekliKlasor.surayaKopyala')"
          ></p>
          <p
            id="s8-11"
            v-html="$t('storageDocument.Depom.tekliKlasor.yildizla')"
          ></p>

          <p
            id="s8-12"
            v-html="$t('storageDocument.Depom.tekliKlasor.geçmis')"
          ></p>
          <p
            id="s8-13"
            v-html="$t('storageDocument.Depom.tekliKlasor.kilitle')"
          ></p>
          <p
            id="s8-14"
            v-html="$t('storageDocument.Depom.tekliKlasor.sil')"
          ></p>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 29"
            imgPath="driveimages/Resim29.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 29"
            imgPath="driveimages/Resim29.png"
          ></yartu-image>
        </div>
      </div>
      <p
        id="s8-15"
        v-html="$t('storageDocument.Depom.tekliKlasor.yanMenu')"
      ></p>
      <p
        v-html="
          $t('storageDocument.Depom.tekliKlasor.yanMenu2', {
            imgPath: require('../assets/driveimages/iconsbuttons/yildiz.png'),
          })
        "
      ></p>
      <subtitle-template
        :text="$t('storageDocument.Depom.cokluKlasor.title')"
      ></subtitle-template>

      <div class="flex flex-row gap-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p
            id="s9-2"
            v-html="$t('storageDocument.Depom.cokluKlasor.giris')"
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 30"
            imgPath="driveimages/Resim30.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 30"
            imgPath="driveimages/Resim30.png"
          ></yartu-image>
        </div>
      </div>
      <p
        v-html="
          $t('storageDocument.Depom.cokluKlasor.cokluSecim', {
            imgPath: require('../assets/driveimages/iconsbuttons/bir.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 31"
        imgPath="driveimages/Resim31.png"
      ></yartu-image>
      <p v-html="$t('storageDocument.Depom.cokluKlasor.cokluSecim2')"></p>
      <yartu-image
        class=""
        imgName="Resim 32"
        imgPath="driveimages/Resim32.png"
      ></yartu-image>
      <div class="px-10">
        <p id="s9-3" v-html="$t('storageDocument.Depom.cokluKlasor.indir')"></p>
        <p
          id="s9-4"
          v-html="$t('storageDocument.Depom.cokluKlasor.surayaTasi')"
        ></p>
        <p
          id="s9-5"
          v-html="$t('storageDocument.Depom.cokluKlasor.surayaKopyala')"
        ></p>
        <p id="s9-6" v-html="$t('storageDocument.Depom.cokluKlasor.sil')"></p>
      </div>
      <p id="s9-7" v-html="$t('storageDocument.Depom.cokluKlasor.menu')"></p>
      <yartu-image
        class=""
        imgName="Resim 33"
        imgPath="driveimages/Resim33.png"
      ></yartu-image>
      <subtitle-template
        id="s9-8"
        :text="$t('storageDocument.Depom.cokluKlasor.klasorSiralaTitle')"
      ></subtitle-template>
      <p v-html="$t('storageDocument.Depom.cokluKlasor.klasörleriSırala')"></p>
      <div class="flex flex-row gap-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p v-html="$t('storageDocument.Depom.cokluKlasor.dosyaAdı')"></p>
          <p
            v-html="$t('storageDocument.Depom.cokluKlasor.düzenlenmeTarihi')"
          ></p>
          <p v-html="$t('storageDocument.Depom.cokluKlasor.a-z')"></p>
          <p v-html="$t('storageDocument.Depom.cokluKlasor.z-a')"></p>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 34"
            imgPath="driveimages/Resim34.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 34"
            imgPath="driveimages/Resim34.png"
          ></yartu-image>
        </div>
      </div>
      <subtitle-template
        id="s10-1"
        :text="$t('storageDocument.Depom.cokluKlasor.listeModuTitle')"
      >
      </subtitle-template>
      <p
        v-html="
          $t('storageDocument.Depom.cokluKlasor.listeModu', {
            imgPath1: require('../assets/driveimages/iconsbuttons/yildiz.png'),
            imgPath2: require('../assets/driveimages/iconsbuttons/ucnokta.png'),
          })
        "
      ></p>

      <yartu-image
        class=""
        imgName="Resim 35"
        imgPath="driveimages/Resim35.png"
      ></yartu-image>
      <subtitle-template
        :text="$t('storageDocument.Depom.dosyaIlslemleri.title')"
      >
      </subtitle-template>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.giris')"></p>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.giris2')"></p>
      <p
        id="s11-1"
        v-html="$t('storageDocument.Depom.dosyaIlslemleri.dosyaIslemleriMenu')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 36"
        imgPath="driveimages/Resim36.png"
      ></yartu-image>
      <p
        id="s11-2"
        v-html="$t('storageDocument.Depom.dosyaIlslemleri.onizleme')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 37"
        imgPath="driveimages/Resim37.png"
      ></yartu-image>
      <p
        id="s11-3"
        v-html="$t('storageDocument.Depom.dosyaIlslemleri.onzilemeEkrani')"
      ></p>
      <div class="px-10">
        <sentace-with-image
          img-path="driveimages/iconsbuttons/solaok.png"
          text=": Dosya önizleme penceresini kapatır"
        ></sentace-with-image>
        <sentace-with-image
          img-path="driveimages/iconsbuttons/share.png"
          :text="$t('storageDocument.Depom.dosyaIlslemleri.onizlemepaylas')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="driveimages/iconsbuttons/download.png"
          :text="$t('storageDocument.Depom.dosyaIlslemleri.indir')"
        ></sentace-with-image>
        <sentace-with-image
          img-path="driveimages/iconsbuttons/ucnoktabutonu.png"
          :text="$t('storageDocument.Depom.dosyaIlslemleri.menu')"
        ></sentace-with-image>

        <div class="flex flex-row gap-6 mx-auto">
          <div
            id="s2-23"
            class="flex flex-col justify-center items-start gap-2 w-full"
          >
            <yartu-image
              class="w-full xs:hidden"
              imgName="Resim 38"
              imgPath="driveimages/Resim38.png"
            ></yartu-image>
            <p
              v-html="
                $t('storageDocument.Depom.dosyaIlslemleri.yenidenAdlandır')
              "
            ></p>
            <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.tası')"></p>
            <p
              v-html="$t('storageDocument.Depom.dosyaIlslemleri.favorile')"
            ></p>
          </div>
          <div class="xs:block hidden w-full">
            <yartu-image
              class=""
              imgName="Resim 38"
              imgPath="driveimages/Resim38.png"
            ></yartu-image>
          </div>
        </div>
        <sentace-with-image
          img-path="driveimages/iconsbuttons/info.png"
          :text="$t('storageDocument.Depom.dosyaIlslemleri.bilgiPenceresi')"
        ></sentace-with-image>
        <yartu-image
          class=""
          imgName="Resim 39"
          imgPath="driveimages/Resim39.png"
        ></yartu-image>
        <div class="flex flex-row gap-6 mx-auto">
          <div
            id="s2-23"
            class="flex flex-col justify-center items-start gap-2 w-full"
          >
            <sentace-with-image
              img-path="driveimages/iconsbuttons/saat.png"
              :text="$t('storageDocument.Depom.dosyaIlslemleri.gecmis')"
            ></sentace-with-image>
            <p
              v-html="
                $t('storageDocument.Depom.cokluKlasor.geriYukleme', {
                  imgPath: require('../assets/driveimages/iconsbuttons/donenok.png'),
                })
              "
            ></p>
            <yartu-image
              class="w-full xs:hidden"
              imgName="Resim 40"
              imgPath="driveimages/Resim40.png"
            ></yartu-image>
          </div>
          <div class="xs:block hidden w-full">
            <yartu-image
              class=""
              imgName="Resim 40"
              imgPath="driveimages/Resim40.png"
            ></yartu-image>
          </div>
        </div>

        <div class="flex flex-row gap-6 mx-auto">
          <div
            id="s2-23"
            class="flex flex-col justify-center items-start gap-2 w-full"
          >
            <sentace-with-image
              img-path="driveimages/iconsbuttons/kisiler.png"
              :text="$t('storageDocument.Depom.dosyaIlslemleri.paylasimDurumu')"
            ></sentace-with-image>
            <yartu-image
              class="w-full xs:hidden"
              imgName="Resim 41"
              imgPath="driveimages/Resim41.png"
            ></yartu-image>
          </div>
          <div class="xs:block hidden w-full">
            <yartu-image
              class=""
              imgName="Resim 41"
              imgPath="driveimages/Resim41.png"
            ></yartu-image>
          </div>
        </div>
      </div>
      <p>
        <span v-html="$t('storageDocument.Depom.dosyaIlslemleri.paylas1')">
        </span>

        <span
          @click="scrollToElement('s12-1')"
          class="text-blue-700 inline-block cursor-pointer"
        >
          {{ $t("storageDocument.Depom.dosyaIlslemleri.paylas2") }}</span
        >
        {{ $t("storageDocument.Depom.dosyaIlslemleri.paylas3") }}
      </p>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.linklePaylas')"></p>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.dosyaIndir')"></p>
      <p
        v-html="$t('storageDocument.Depom.dosyaIlslemleri.epostaIleGonder')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 42"
        imgPath="driveimages/Resim42.png"
      ></yartu-image>
      <p
        v-html="
          $t('storageDocument.Depom.dosyaIlslemleri.dosyaYenidenAdlandır')
        "
      ></p>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.surayaTasi')"></p>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.surayaKopyala')"></p>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.yildizla')"></p>
      <p
        v-html="
          ($t('storageDocument.Depom.dosyaIlslemleri.dosyaGecmis'),
          {
            imgPath: require('../assets/driveimages/iconsbuttons/donenok.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 40"
        imgPath="driveimages/Resim40.png"
      ></yartu-image>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.kilitle')"></p>
      <p v-html="$t('storageDocument.Depom.dosyaIlslemleri.sil')"></p>
      <p
        id="s11-4"
        v-html="$t('storageDocument.Depom.dosyaIlslemleri.sagMenu')"
      ></p>
      <p
        v-html="
          $t('storageDocument.Depom.dosyaIlslemleri.favori', {
            imgPath: require('../assets/driveimages/iconsbuttons/donenok.png'),
          })
        "
      ></p>
      <subtitle-template
        id="s11-5"
        :text="$t('storageDocument.Depom.cokluDosyaIslemleri.title')"
      ></subtitle-template>
      <p v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.giris')"></p>
      <yartu-image
        class=""
        imgName="Resim 43"
        imgPath="driveimages/Resim43.png"
      ></yartu-image>
      <p
        v-html="
          $t('storageDocument.Depom.cokluDosyaIslemleri.hepsi', {
            imgPath: require('../assets/driveimages/iconsbuttons/bir.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 44"
        imgPath="driveimages/Resim44.png"
      ></yartu-image>
      <div class="px-10">
        <p v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.indir')"></p>

        <p
          v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.surayaKopyala')"
        ></p>
        <p v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.sil')"></p>
      </div>
      <p v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.menu')"></p>
      <yartu-image
        class=""
        imgName="Resim 45"
        imgPath="driveimages/Resim45.png"
      ></yartu-image>
      <p
        id="s11-6"
        v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.dosyalariSirala')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 46"
        imgPath="driveimages/Resim46.png"
      ></yartu-image>
      <div class="px-10">
        <p
          v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.dosyaAdı')"
        ></p>
        <p v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.tarih')"></p>
        <p v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.a-z')"></p>
        <p v-html="$t('storageDocument.Depom.cokluDosyaIslemleri.z-a')"></p>
      </div>
      <subtitle-template
        id="s11-7"
        text="Liste Modunda Dosya İşlemleri"
      ></subtitle-template>
      <p
        v-html="
          $t('storageDocument.Depom.cokluDosyaIslemleri.favorile', {
            imgPath1: require('../assets/driveimages/iconsbuttons/yildiz.png'),
            imgPath2: require('../assets/driveimages/iconsbuttons/ucnokta.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 47"
        imgPath="driveimages/Resim47.png"
      ></yartu-image>
      <content-title :text="$t('storageDocument.depolar.title')">
      </content-title>
      <p
        id="s21-1"
        v-html="
          $t('storageDocument.depolar.yeniDepo.yeniDepoOlustur', {
            imgPath1: require('../assets/driveimages/iconsbuttons/depolar.png'),
            imgPath2: require('../assets/driveimages/iconsbuttons/depoolustur.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 48"
        imgPath="driveimages/Resim4.png"
      ></yartu-image>
      <p v-html="$t('storageDocument.depolar.yeniDepo.depoListesi')"></p>
      <subtitle-template
        id="s21-2"
        :text="$t('storageDocument.depolar.depoIslemleri.title')"
      ></subtitle-template>
      <p v-html="$t('storageDocument.depolar.depoIslemleri.giris')"></p>
      <yartu-image
        class=""
        imgName="Resim 49"
        imgPath="driveimages/Resim49.png"
      ></yartu-image>
      <yartu-image
        class=""
        imgName="Resim 50"
        imgPath="driveimages/Resim50.png"
      ></yartu-image>
      <div class="px-6 flex flex-col gap-2">
        <p>
          <span
            v-html="$t('storageDocument.depolar.depoIslemleri.depoyaYukleme1')"
          >
          </span>

          <span
            @click="scrollToElement('s2-1')"
            class="text-blue-700 inline cursor-pointer"
            >{{
              $t("storageDocument.depolar.depoIslemleri.depoyaYukleme2")
            }}</span
          >
          {{ $t("storageDocument.depolar.depoIslemleri.depoyaYukleme3") }}
        </p>
        <p>
          <span
            v-html="
              $t('storageDocument.depolar.depoIslemleri.depodaOlusturma1')
            "
          ></span>

          <span
            @click="scrollToElement('s6-2')"
            class="text-blue-700 inline cursor-pointer"
            >{{
              $t("storageDocument.depolar.depoIslemleri.depodaOlusturma2")
            }}</span
          >
          {{ $t("storageDocument.depolar.depoIslemleri.depodaOlusturma3") }}
        </p>
        <p
          class="pb-2"
          id="s21-2"
          v-html="$t('storageDocument.depolar.depoIslemleri.depoButonlari')"
        ></p>

        <div class="flex flex-col px-4 gap-1">
          <p
            v-html="$t('storageDocument.depolar.araclar.dosyaKlasorSayisi')"
          ></p>
          <sentace-with-image
            id="s22-1"
            img-path="driveimages/iconsbuttons/donenok.png"
            :text="$t('storageDocument.depolar.araclar.icerikYenileme')"
          ></sentace-with-image>

          <div class="flex flex-row">
            <div class="flex justify-end items-start">
              <img src="../assets/driveimages/iconsbuttons/artıperson.png" />
              <y-icon
                class="p-2"
                v-if="iconname !== ''"
                :name="[iconname]"
              ></y-icon>
            </div>
            <p class="" id="s22-2">
              {{ $t("storageDocument.depolar.araclar.depoPaylasma1") }}
              <span
                @click="scrollToElement('s12-1')"
                class="text-blue-700 inline-block cursor-pointer"
                >{{ $t("storageDocument.depolar.araclar.depoPaylasma2") }}</span
              >
              {{ $t("storageDocument.depolar.araclar.depoPaylasma3") }}
            </p>
          </div>
          <sentace-with-image
            id="s22-3"
            img-path="driveimages/iconsbuttons/depo.png"
            :text="$t('storageDocument.depolar.araclar.gorunum')"
          ></sentace-with-image>
          <yartu-image
            class=""
            imgName="Resim 24"
            imgPath="driveimages/Resim24.png"
          ></yartu-image>
          <yartu-image
            class=""
            imgName="Resim 25"
            imgPath="driveimages/Resim25.png"
          ></yartu-image>
          <sentace-with-image
            id="s22-4"
            img-path="driveimages/iconsbuttons/info.png"
            :text="$t('storageDocument.depolar.araclar.bilgi')"
          ></sentace-with-image>

          <yartu-image
            class=""
            imgName="Resim 26"
            imgPath="driveimages/Resim26.png"
          ></yartu-image>
          <p
            id="s22-5"
            v-html="
              $t('storageDocument.depolar.araclar.ekIslem', {
                imgPath: require('../assets/driveimages/iconsbuttons/ucnokta.png'),
              })
            "
          ></p>
          <p v-html="$t('storageDocument.depolar.araclar.copKutusu')"></p>
          <p v-html="$t('storageDocument.depolar.araclar.gecmis')"></p>
          <yartu-image
            class=""
            imgName="Resim 27"
            imgPath="driveimages/Resim27.png"
          ></yartu-image>
        </div>
      </div>
      <subtitle-template
        id="s21-3"
        :text="$t('storageDocument.depolar.depoSilme.title')"
      ></subtitle-template>

      <p v-html="$t('storageDocument.depolar.depoSilme.silme')"></p>
      <yartu-image
        class=""
        imgName="Resim 51"
        imgPath="driveimages/Resim51.png"
      ></yartu-image>
      <subtitle-template
        id="s21-4"
        :text="$t('storageDocument.depolar.parolaliDepo.title')"
      ></subtitle-template>
      <p>
        {{ $t("storageDocument.depolar.parolaliDepo.parolaliErisim1") }}
        <span
          @click="scrollToElement('s4')"
          class="text-blue-700 inline cursor-pointer"
          >{{
            $t("storageDocument.depolar.parolaliDepo.parolaliErisim2")
          }}</span
        >
        {{ $t("storageDocument.depolar.parolaliDepo.parolaliErisim3") }}.
      </p>
      <content-title
        :text="$t('storageDocument.paylasimIslemleri.title')"
      ></content-title>
      <p v-html="$t('storageDocument.paylasimIslemleri.giris')"></p>
      <subtitle-template id="s12-1" text="Depo Paylaşımı"></subtitle-template>
      <p
        v-html="
          $t('storageDocument.paylasimIslemleri.depoPaylas.content', {
            imgPath: require('../assets/driveimages/iconsbuttons/artıperson.png'),
          })
        "
      ></p>
      <subtitle-template id="s12-2" text="Klasör Paylaşımı"></subtitle-template>
      <p
        v-html="
          $t('storageDocument.paylasimIslemleri.klasorPaylas.content', {
            imgPath: require('../assets/driveimages/iconsbuttons/ucnoktabutonu.png'),
          })
        "
      ></p>
      <subtitle-template id="s12-3" text="Dosya Paylaşımı"></subtitle-template>
      <p
        v-html="
          $t('storageDocument.paylasimIslemleri.dosyaPaylas.content', {
            imgPath: require('../assets/driveimages/iconsbuttons/ucnoktabutonu.png'),
          })
        "
      ></p>
      <yartu-image
        id="s12-4"
        class=""
        imgName="Resim 52"
        imgPath="driveimages/Resim52.png"
      ></yartu-image>
      <subtitle-template
        id="s12-4"
        :text="$t('storageDocument.paylasimIslemleri.paylasimEkrani.title')"
      ></subtitle-template>
      <p
        v-html="$t('storageDocument.paylasimIslemleri.paylasimEkrani.giris')"
      ></p>

      <p
        id="s13-1"
        v-html="
          $t('storageDocument.paylasimIslemleri.paylasimEkrani.izinlePaylas')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 53"
        imgPath="driveimages/Resim53.png"
      ></yartu-image>
      <div class="flex flex-col gap-2 px-10">
        <p
          id="s13-2"
          v-html="$t('storageDocument.paylasimIslemleri.paylasimEkrani.oku')"
        ></p>
        <p
          id="s13-3"
          v-html="
            $t('storageDocument.paylasimIslemleri.paylasimEkrani.oku-yaz')
          "
        ></p>
        <p
          id="s13-4"
          v-html="
            $t('storageDocument.paylasimIslemleri.paylasimEkrani.yonetici')
          "
        ></p>
        <p
          id="s13-5"
          v-html="
            $t('storageDocument.paylasimIslemleri.paylasimEkrani.ozelIzin')
          "
        ></p>
      </div>
      <yartu-image
        id="s12-5"
        class=""
        imgName="Resim 54"
        imgPath="driveimages/Resim54.png"
      ></yartu-image>
      <p
        v-html="
          $t('storageDocument.paylasimIslemleri.ozelIzinler.giris', {
            imgPath1: require('../assets/driveimages/iconsbuttons/eksi.png'),
            imgPath2: require('../assets/driveimages/iconsbuttons/tik.png'),
          })
        "
      ></p>
      <div class="px-10 gap-2 flex flex-col">
        <p
          v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.indir')"
        ></p>
        <p
          v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.yukle')"
        ></p>
        <p
          v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.duzenle')"
        ></p>
        <p
          v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.olustur')"
        ></p>
        <p v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.sil')"></p>
        <p
          v-html="
            $t('storageDocument.paylasimIslemleri.ozelIzinler.nesneKopyala')
          "
        ></p>
        <p
          v-html="
            $t('storageDocument.paylasimIslemleri.ozelIzinler.onizlemeKopyala')
          "
        ></p>
        <p
          v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.link')"
        ></p>
        <p
          v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.paylas')"
        ></p>
      </div>
      <p
        v-html="
          $t('storageDocument.paylasimIslemleri.ozelIzinler.kullaniciTanimla')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 55"
        imgPath="driveimages/Resim55.png"
      ></yartu-image>
      <p
        v-html="$t('storageDocument.paylasimIslemleri.ozelIzinler.kaydet')"
      ></p>
      <subtitle-template
        id="s12-6"
        :text="$t('storageDocument.paylasimIslemleri.paylasimAyarlari.title')"
      >
      </subtitle-template>
      <p
        v-html="$t('storageDocument.paylasimIslemleri.paylasimAyarlari.giris')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 56"
        imgPath="driveimages/Resim56.png"
      ></yartu-image>

      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <p
            id="s14-1"
            v-html="
              $t(
                'storageDocument.paylasimIslemleri.paylasimAyarlari.yetkiDegisme',
                {
                  imgPath: require('../assets/driveimages/iconsbuttons/bir.png'),
                }
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 57"
            imgPath="driveimages/Resim57.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 57"
            imgPath="driveimages/Resim57.png"
          ></yartu-image>
        </div>
      </div>
      <p
        id="s14-2"
        v-html="
          $t(
            'storageDocument.paylasimIslemleri.paylasimAyarlari.paylasimSonlandırma',
            {
              imgPath: require('../assets/driveimages/iconsbuttons/carpi.png'),
            }
          )
        "
      ></p>
      <content-title :text="$t('storageDocument.paylasim.title')">
      </content-title>
      <p v-html="$t('storageDocument.paylasim.giris')"></p>
      <yartu-image
        class=""
        imgName="Resim 58"
        imgPath="driveimages/Resim58.png"
      ></yartu-image>
      <subtitle-template
        id="s15-1"
        :text="$t('storageDocument.paylasim.benimlePaylasilan.title')"
      ></subtitle-template>
      <p v-html="$t('storageDocument.paylasim.benimlePaylasilan.giris')"></p>
      <p
        id="s16-1"
        v-html="
          $t('storageDocument.paylasim.benimlePaylasilan.nesneUzerineIslem', {
            imgPath: require('../assets/driveimages/iconsbuttons/ucnoktabutonu.png'),
          })
        "
      ></p>
      <div class="flex flex-col gap-2 px-10">
        <p
          id="s16-1"
          v-html="$t('storageDocument.paylasim.benimlePaylasilan.ac')"
        ></p>
        <p
          id="s16-2"
          v-html="$t('storageDocument.paylasim.benimlePaylasilan.incele')"
        ></p>
        <p
          id="s16-3"
          v-html="$t('storageDocument.paylasim.benimlePaylasilan.indir')"
        ></p>
        <p
          id="s16-9"
          v-html="
            $t('storageDocument.paylasim.benimlePaylasilan.yildizli', {
              imgPath: require('../assets/driveimages/iconsbuttons/yildiz.png'),
            })
          "
        ></p>
        <p
          id="s16-4"
          v-html="
            $t('storageDocument.paylasim.benimlePaylasilan.epostaIleGonder')
          "
        ></p>
        <p
          id="s16-10"
          v-html="
            $t('storageDocument.paylasim.benimlePaylasilan.yenidenAdlandir')
          "
        ></p>
        <p
          id="s16-5"
          v-html="
            $t('storageDocument.paylasim.benimlePaylasilan.surayaKopyala')
          "
        ></p>
        <p
          id="s16-6"
          v-html="$t('storageDocument.paylasim.benimlePaylasilan.detaylar')"
        ></p>
        <p
          id="s16-7"
          v-html="$t('storageDocument.paylasim.benimlePaylasilan.kilitle')"
        ></p>
        <p
          id="s16-8"
          v-html="
            $t('storageDocument.paylasim.benimlePaylasilan.paylasimiKaldir')
          "
        ></p>
      </div>
      <subtitle-template
        id="s15-2"
        :text="$t('storageDocument.paylasim.benimPaylastiklarim.title')"
      ></subtitle-template>
      <p v-html="$t('storageDocument.paylasim.benimPaylastiklarim.giris')"></p>
      <yartu-image
        class=""
        imgName="Resim 59"
        imgPath="driveimages/Resim59.png"
      ></yartu-image>
      <content-title
        id="s17"
        :text="$t('storageDocument.baglantiPaylasimi.title')"
      ></content-title>
      <p v-html="$t('storageDocument.baglantiPaylasimi.giris')"></p>
      <yartu-image
        class=""
        imgName="Resim 60"
        imgPath="driveimages/Resim60.png"
      ></yartu-image>
      <p
        v-html="
          $t('storageDocument.baglantiPaylasimi.giris2', {
            imgPath1: require('../assets/driveimages/iconsbuttons/bir.png'),
            imgPath2: require('../assets/driveimages/iconsbuttons/iki.png'),
          })
        "
      ></p>
      <subtitle-template
        id="s17-1"
        :text="$t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.title')"
      ></subtitle-template>
      <p
        v-html="$t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.giris')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 61"
        imgPath="driveimages/Resim61.png"
      ></yartu-image>

      <div class="flex flex-row gap-6 mx-auto">
        <div
          id="s2-23"
          class="flex flex-col justify-center items-start gap-2 w-full"
        >
          <p
            v-html="
              $t(
                'storageDocument.baglantiPaylasimi.yuklemeNoktasi.ilkYukleme',
                {
                  imgPath: require('../assets/driveimages/iconsbuttons/newuploadbutton.png'),
                }
              )
            "
          ></p>
          <p
            v-html="
              $t(
                'storageDocument.baglantiPaylasimi.yuklemeNoktasi.yuklemeTitle'
              )
            "
          ></p>

          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 62"
            imgPath="driveimages/Resim62.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 62"
            imgPath="driveimages/Resim62.png"
          ></yartu-image>
        </div>
      </div>
      <p
        id="s18-1"
        v-html="
          $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.yuklencekDosya')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 63"
        imgPath="driveimages/Resim63.png"
      ></yartu-image>
      <p
        id="s18-2"
        v-html="
          $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.gelismisAyarlar')
        "
      ></p>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <p
            v-html="
              $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.sonKullanma')
            "
          ></p>
          <p
            v-html="
              $t(
                'storageDocument.baglantiPaylasimi.yuklemeNoktasi.sifreBelirle'
              )
            "
          ></p>

          <p
            v-html="
              $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.adetSiniri')
            "
          ></p>
          <p
            v-html="
              $t(
                'storageDocument.baglantiPaylasimi.yuklemeNoktasi.dosyaBoyutSiniri'
              )
            "
          ></p>
          <p
            v-html="
              $t(
                'storageDocument.baglantiPaylasimi.yuklemeNoktasi.dosyaUzantıları'
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 64"
            imgPath="driveimages/Resim64.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 64"
            imgPath="driveimages/Resim64.png"
          ></yartu-image>
        </div>
      </div>
      <p
        id="s18-3"
        v-html="
          $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.listeleme')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 65"
        imgPath="driveimages/Resim65.png"
      ></yartu-image>
      <p
        v-html="
          $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.bilgiPenceresi')
        "
      ></p>
      <p
        id="s18-4"
        v-html="
          $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.menu', {
            imgPath: require('../assets/driveimages/iconsbuttons/ucnoktabutonu.png'),
          })
        "
      ></p>
      <div class="px-10 flex flex-col gap-2">
        <p
          v-html="
            $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.linkKopyala')
          "
        ></p>
        <p
          v-html="
            $t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.düzenle')
          "
        ></p>
        <p
          v-html="$t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.sil')"
        ></p>
      </div>
      <subtitle-template
        id="s19-1"
        :text="$t('storageDocument.baglantiPaylasimi.indirmeBaglantisi.title')"
      >
      </subtitle-template>
      <p
        v-html="$t('storageDocument.baglantiPaylasimi.yuklemeNoktasi.giris')"
      ></p>
      <p
        id="s20-1"
        v-html="
          $t(
            'storageDocument.baglantiPaylasimi.indirmeBaglantisi.linkOlusturma1',
            {
              imgPath: require('../assets/driveimages/iconsbuttons/ucnoktabutonu.png'),
            }
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 66"
        imgPath="driveimages/Resim66.png"
      ></yartu-image>
      <p
        v-html="
          $t(
            'storageDocument.baglantiPaylasimi.indirmeBaglantisi.linkOlusturma2'
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 67"
        imgPath="driveimages/Resim67.png"
      ></yartu-image>
      <p
        id="s20-2"
        v-html="
          $t(
            'storageDocument.baglantiPaylasimi.indirmeBaglantisi.linkKopyala',
            {
              imgPath: require('../assets/driveimages/iconsbuttons/bir.png'),
            }
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 68"
        imgPath="driveimages/Resim68.png"
      ></yartu-image>
      <div class="px-10 flex flex-col gap-2">
        <p
          v-html="
            $t('storageDocument.baglantiPaylasimi.indirmeBaglantisi.sifre')
          "
        ></p>
        <p
          v-html="
            $t(
              'storageDocument.baglantiPaylasimi.indirmeBaglantisi.sonKullanma'
            )
          "
        ></p>
        <p
          v-html="
            $t(
              'storageDocument.baglantiPaylasimi.indirmeBaglantisi.indirmeLimiti'
            )
          "
        ></p>
      </div>
      <p
        id="s20-3"
        v-html="
          $t(
            'storageDocument.baglantiPaylasimi.indirmeBaglantisi.listelemeDegistirme'
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 69"
        imgPath="driveimages/Resim69.png"
      ></yartu-image>
      <p
        id="s20-4"
        v-html="
          $t('storageDocument.baglantiPaylasimi.indirmeBaglantisi.bilgi', {
            imgPath: require('../assets/driveimages/iconsbuttons/ucnoktabutonu.png'),
          })
        "
      ></p>
      <div class="px-10 flex flex-col gap-2">
        <p
          v-html="
            $t(
              'storageDocument.baglantiPaylasimi.indirmeBaglantisi.linkiKopyala'
            )
          "
        ></p>
        <p
          v-html="
            $t('storageDocument.baglantiPaylasimi.indirmeBaglantisi.düzenle')
          "
        ></p>
        <p
          v-html="$t('storageDocument.baglantiPaylasimi.indirmeBaglantisi.sil')"
        ></p>
      </div>
    </template>

    <template #drawer>
      <div class="">
        <complate-drawer
          :menuList="menus"
          @clicked="scrollToElement"
        ></complate-drawer>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";

import { defineCustomElement, ref, watch, onMounted } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";
import router from "@/router";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import SentaceWithImage from "../components/SentaceWithImage.vue";
import ComplateDrawer from "@/components/ComplateDrawer.vue";
import { onBeforeUnmount, onBeforeRouteEnter, useRoute } from "vue-router";

const openDrawer = ref(false);
const drawerStatus = ref(false);
const route = useRoute();

watch(route, (newroute, oldroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.elementId !== "") {
    console.log("data is", route.Id);
    scrollToElement(route.query.Id);
  }
});

const scrollToElement = (elementId) => {
  console.log(elementId);
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Drive Ana Sayfa",
    elementId: "s1",
    subtitles: [
      {
        title: "Dosya Yönetim Menüsü",
        elementId: "s1-1",
      },
      {
        title: "Ana Sayfa Butonları",
        elementId: "s1-2",
      },

      {
        title: "Dosya Yükleme",
        elementId: "s2",
        content: [
          {
            title: "Yükleme Penceresi",
            elementId: "s2-1",
          },
          {
            title: "Yeni Depo Oluşturmak",
            elementId: "s1-3",
          },

          {
            title: "Yeni Klasör Penceresi",
            elementId: "s2-2",
          },
          {
            title: "Yüklenecek Dosyaların Seçimi",
            elementId: "s2-3",
          },
          {
            title: "Yükleme Adresi Değişikliği",
            elementId: "s2-4",
          },
          {
            title: "Yükleme Detay Ekranı",
            elementId: "s2-5",
          },
        ],
      },
      {
        title: "Klasör Yükleme",
        elementId: "s3",
        content: [
          {
            title: "Yükleme Penceresi",
            elementId: "s3-1",
          },
          {
            title: "Yeni Depo",
            elementId: "s3-6",
          },
          {
            title: "Klasör Penceresi",
            elementId: "s3-2",
          },
          {
            title: "Yeni Klasör Ekleme",
            elementId: "s3-7",
          },
          {
            title: "Yüklenecek Klasörlerin Seçimi",
            elementId: "s3-3",
          },
          {
            title: "Yükleme Adresi Değişikliği",
            elementId: "s3-4",
          },
          {
            title: "Yükleme Detay Ekranı",
            elementId: "s3-5",
          },
        ],
      },
      {
        title: "Şifreli Depo Oluşturmak",
        elementId: "s4",
      },
    ],
  },
  {
    title: "Depom Sayfası",
    elementId: "s-5",
    subtitles: [
      {
        title: "Sayfaya Erişim",
        elementId: "s5-1",
      },
      {
        title: "Yükleme",
        elementId: "s5-2",
      },
      {
        title: "Yeni Oluşturma",
        elementId: "s6-1",
        content: [
          {
            title: "Depo",
            elementId: "s6-2",
          },
          {
            title: "Klasör",
            elementId: "s6-3",
          },
          {
            title: "Doküman",
            elementId: "s6-4",
          },
          {
            title: "Hesap Tablosu",
            elementId: "s6-5",
          },
          {
            title: "Sunum Dosyası",
            elementId: "s6-6",
          },
        ],
      },
      {
        title: "Araç Çubuğu",
        elementId: "s7-1",
        content: [
          {
            title: "İçeriği Yenileme",
            elementId: "s7-2",
          },
          {
            title: "Depoyu Paylaşma",
            elementId: "s7-3",
          },
          {
            title: "Depo Görünümü",
            elementId: "s7-4",
          },
          {
            title: "Depo Bilgisi",
            elementId: "s7-5",
          },
          {
            title: "Ek İşlmler",
            elementId: "s7-6",
          },
        ],
      },
      {
        title: "Listeleme Bölümü",
        elementId: "s5-3",
      },
      {
        title: "Tekli Klasör İşlemleri",
        elementId: "s8-1",
        content: [
          {
            title: "Klasör İşlemleri Menüsü",
            elementId: "s8-2",
          },
          {
            title: "Giriş",
            elementId: "s8-3",
          },
          {
            title: "Paylaşma",
            elementId: "s8-4",
          },
          {
            title: "Link ile Paylaşma",
            elementId: "s8-5",
          },
          {
            title: "İndirme",
            elementId: "s8-6",
          },
          {
            title: "E-mail ile Gönderme",
            elementId: "s8-7",
          },
          {
            title: "Yeniiden Adlandırma",
            elementId: "s8-8",
          },
          {
            title: "Taşıma",
            elementId: "s8-9",
          },
          {
            title: "Kopyalama",
            elementId: "s8-10",
          },
          {
            title: "Favoriler",
            elementId: "s8-11",
          },
          {
            title: "Geçmiş",
            elementId: "s8-12",
          },
          {
            title: "Kilitli Klasör",
            elementId: "s8-13",
          },
          {
            title: "Silme",
            elementId: "s8-14",
          },
          {
            title: "Yan Menü",
            elementId: "s8-15",
          },
        ],
      },
      {
        title: "Çoklu Klasör Oluşturmak",
        elementId: "s9-1",
        content: [
          {
            title: "Çoklu Seçim",
            elementId: "s9-2",
          },
          {
            title: "İndirme",
            elementId: "s9-3",
          },
          {
            title: "Taşıma",
            elementId: "s9-4",
          },
          {
            title: "Kopyalama",
            elementId: "s9-5",
          },
          {
            title: "Silme",
            elementId: "s9-6",
          },
          {
            title: "Yan Menü",
            elementId: "s9-7",
          },
          {
            title: "Klasör Sıralama",
            elementId: "s9-8",
          },
          {
            title: "Liste Modunda Klasör İşlemleri",
            elementId: "s10-1",
          },
        ],
      },
      {
        title: "Dosya İşlemleri",
        elementId: "s11",
        content: [
          {
            title: "Dosya İşlemleri Menüsü",
            elementId: "s11-1",
          },
          {
            title: "Önizleme",
            elementId: "s11-2",
          },
          {
            title: "Önizleme Ekranı Seçenekleri",
            elementId: "s11-3",
          },
          {
            title: "Yan Menü",
            elementId: "s11-4",
          },
        ],
      },
      {
        title: "Çoklu Dosya İşlemleri",
        elementId: "s11-51",
        content: [
          {
            title: "Çoklu Dosya Seçimi",
            elementId: "s11-5",
          },
          {
            title: "Dosya Sırlama",
            elementId: "s11-6",
          },
          {
            title: "Liste Modunda Dosya İşlemleri",
            elementId: "s11-7",
          },
        ],
      },
    ],
  },
  {
    title: "Depolar",
    subtitles: [
      {
        title: "Yeni Depo Oluşturma",
        elementId: "s21-1",
      },
      {
        title: "Depo işlemleri",
        elementId: "s21-2",
      },
      {
        title: "Araçlar",
        elementId: "s21-3",
        content: [
          {
            title: "İçeriği Yenileme",
            elementId: "s22-1",
          },
          {
            title: "Paylaşma",
            elementId: "s22-2",
          },
          {
            title: "Görünümü Değiştirme",
            elementId: "s22-3",
          },
          {
            title: "Depo Bilgileri",
            elementId: "s22-4",
          },
          {
            title: "Ek İşlemler",
            elementId: "s22-5",
          },
        ],
      },
      {
        title: "Depo Silme",
        elementId: "s21-3",
      },
      {
        title: "Parola Korumalı Depo",
        elementId: "s21-4",
      },
    ],
  },
  {
    title: "Paylaşım İşlemleri",
    subtitles: [
      {
        title: "Depo Paylaşımı",
        elementId: "s12-1",
      },
      {
        title: "Klasör Paylaşımı",
        elementId: "s12-2",
      },
      {
        title: "Dosya Paylaşımı",
        elementId: "s12-3",
      },
      {
        title: "Paylaşım Ekranı",
        elementId: "s12-4",
        content: [
          {
            title: "İzinle Paylaş",
            elementId: "s13-1",
          },
          {
            title: "Oku",
            elementId: "s13-2",
          },
          {
            title: "Oku-Yaz",
            elementId: "s13-3",
          },
          {
            title: "Yönetici",
            elementId: "s13-4",
          },
          {
            title: "Özel İzin Tanımla",
            elementId: "s13-5",
          },
        ],
      },
      {
        title: "Özel İzinler",
        elementId: "s12-5",
      },
      {
        title: "Paylaşım Ayarlarının Değiştirilmesi",
        elementId: "s12-6",
        content: [
          {
            title: "Paylaşım Yetkisinin Değiştirilmesi",
            elementId: "s14-1",
          },
          {
            title: "Paylaşımın Sonlandırılması",
            elementId: "s14-1",
          },
        ],
      },
    ],
  },
  {
    title: "Paylaşım",
    subtitles: [
      {
        title: "Benimle Paylaşılanlar",
        elemnetId: "s15-1",
        content: [
          {
            title: "Paylaşılan Nesne İşlemleri",
            elementId: "s16-1",
          },
          {
            title: "Aç",
            elementId: "s16-1",
          },
          {
            title: "İncele",
            elementId: "s16-2",
          },
          {
            title: "İndir",
            elementId: "s16-3",
          },
          {
            title: "E-posta ile Gönder",
            elementId: "s16-4",
          },
          {
            title: "Şuraya Kopyala",
            elementId: "s16-5",
          },
          {
            title: "Detaylar",
            elementId: "s16-6",
          },
          {
            title: "Kilitle",
            elementId: "s16-7",
          },
          {
            title: "Paylaşımı Kaldır",
            elementId: "s16-8",
          },
        ],
      },
      {
        title: "Benim Paylaştıklarım",
        elementId: "s15-2",
      },
    ],
  },
  {
    title: "Bağlantı Paylaşımı",
    elementId: "s17",
    subtitles: [
      {
        title: "Yükleme Noktası",
        elementId: "s17-1",
        content: [
          {
            title: "Yüklenen Dosyalar Klasörü",
            elementId: "s18-1",
          },
          {
            title: "Gelişmiş Ayarlar",
            elementId: "s18-2",
          },
          {
            title: " Link Listesi",
            elementId: "s18-3",
          },
          {
            title: "Link İşlemleri",
            elementId: "s18-4",
          },
        ],
      },
      {
        title: "Oluşturulan Dosyalar",
        elementId: "s19-1",
        content: [
          {
            title: "İndirme Bağlantısı Oluşturma",
            elementId: "s20-1",
          },
          {
            title: "Gelişmiş Ayarlar",
            elementId: "s20-2",
          },
          {
            title: " Bağlantı Listeleme ve Değiştirme",
            elementId: "s20-3",
          },
          {
            title: "Bağlantı İşlemleri",
            elementId: "s20-3",
          },
        ],
      },
    ],
  },
]);
</script>
