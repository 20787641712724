<template>
  <pagetemplate class="" main-title="Takvim" imgPath="calendar.svg">
    <template #smallDrawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
    <template #content>
      <content-title :text="$t('calendarDocument.Anasayfa.title')">
      </content-title>
      <p
        v-html="
          $t('calendarDocument.Anasayfa.anaEkran', {
            imgPath: require('../assets/calendarimages/icons/takvim.png'),
          })
        "
      ></p>
      <subtitle-template
        id="s1-1"
        :text="$t('calendarDocument.Anasayfa.aylikTakvim.kucukTakvimTitle')"
      >
      </subtitle-template>

      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <p
            v-html="$t('calendarDocument.Anasayfa.aylikTakvim.kucukTakvim')"
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 1"
            imgPath="driveimages/Resim1.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 1"
            imgPath="driveimages/Resim1.png"
          ></yartu-image>
        </div>
      </div>
      <p
        id="s1-2"
        v-html="
          $t('calendarDocument.Anasayfa.anaEkranTakvimi.anaEkranTakvimi', {
            imgPath: require('../assets/calendarimages/icons/bir.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 2"
        imgPath="calendarimages/Resim2.png"
      ></yartu-image>
      <p
        v-html="
          $t('calendarDocument.Anasayfa.anaEkranTakvimi.anaEkranTakvimi2', {
            imgPath: require('../assets/calendarimages/icons/iki.png'),
          })
        "
      ></p>
      <content-title :text="$t('calendarDocument.TakvimIslemleri.title')">
      </content-title>
      <p v-html="$t('calendarDocument.TakvimIslemleri.takvimAciklama')"></p>
      <yartu-image
        class=""
        imgName="Resim 3"
        imgPath="calendarimages/Resim3.png"
      ></yartu-image>
      <subtitle-template
        id="s2-1"
        :text="$t('calendarDocument.TakvimIslemleri.yeniTakvimOlusturma.title')"
      >
      </subtitle-template>
      <p
        v-html="
          $t(
            'calendarDocument.TakvimIslemleri.yeniTakvimOlusturma.yeniTakvim',
            {
              imgPath: require('../assets/calendarimages/icons/newcalendar.png'),
            }
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 4"
        imgPath="calendarimages/Resim4.png"
      ></yartu-image>
      <p
        v-html="
          $t('calendarDocument.TakvimIslemleri.yeniTakvimOlusturma.yeniTakvim2')
        "
      ></p>
      <div class="px-10 flex flex-col gap-2">
        <p
          id="s3-1"
          v-html="
            $t(
              'calendarDocument.TakvimIslemleri.yeniTakvimOlusturma.takvimIsmi'
            )
          "
        ></p>
        <p
          id="s3-2"
          v-html="
            $t(
              'calendarDocument.TakvimIslemleri.yeniTakvimOlusturma.renkSecimi'
            )
          "
        ></p>
        <p
          id="s3-3"
          v-html="
            $t(
              'calendarDocument.TakvimIslemleri.yeniTakvimOlusturma.calvedProtokolu'
            )
          "
        ></p>
      </div>
      <yartu-image
        class=""
        imgName="Resim 5"
        imgPath="calendarimages/Resim5.png"
      ></yartu-image>
      <subtitle-template
        :text="
          $t(
            'calendarDocument.TakvimIslemleri.takvimleriPaylasma.takvimPaylasmaTitle'
          )
        "
      ></subtitle-template>

      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <p
            id="s4-1"
            v-html="
              $t(
                'calendarDocument.TakvimIslemleri.takvimleriPaylasma.takvimPaylasma',
                {
                  imgPath1: require('../assets/calendarimages/icons/ucnokta.png'),
                  imgPath2: require('../assets/calendarimages/icons/share.png'),
                }
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 6"
            imgPath="calendarimages/Resim6.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 6"
            imgPath="calendarimages/Resim6.png"
          ></yartu-image>
        </div>
      </div>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <p
            id="s4-2"
            v-html="
              $t(
                'calendarDocument.TakvimIslemleri.takvimleriPaylasma.paylasimEkrani',
                {
                  imgPath: require('../assets/calendarimages/icons/read.png'),
                }
              )
            "
          ></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 7"
            imgPath="calendarimages/Resim7.png"
          ></yartu-image>
          <p
            id="s4-3"
            v-html="
              $t(
                'calendarDocument.TakvimIslemleri.takvimleriPaylasma.paylasilacakKisiler'
              )
            "
          ></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 7"
            imgPath="calendarimages/Resim7.png"
          ></yartu-image>
        </div>
      </div>

      <subtitle-template
        id="s2-3"
        :text="
          $t(
            'calendarDocument.TakvimIslemleri.takvimleriDuzenleme.takvimDuzenlemeTitle'
          )
        "
      ></subtitle-template>
      <p
        v-html="
          $t(
            'calendarDocument.TakvimIslemleri.takvimleriDuzenleme.takvimDüzenleme',
            {
              imgPath1: require('../assets/calendarimages/icons/ucnokta.png'),
              imgPath2: require('../assets/calendarimages/icons/edit.png'),
            }
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 8"
        imgPath="calendarimages/Resim8.png"
      ></yartu-image>
      <subtitle-template
        id="s2-5"
        :text="
          $t(
            'calendarDocument.TakvimIslemleri.takvimGorunurlugu.takvimGorunurluguTitle'
          )
        "
      ></subtitle-template>
      <p
        v-html="
          $t(
            'calendarDocument.TakvimIslemleri.takvimGorunurlugu.tavimGorunurluguDegistirilmesi',
            {
              imgPath1: require('../assets/calendarimages/icons/ucnokta.png'),
              imgPath2: require('../assets/calendarimages/icons/show.png'),
              imgPath3: require('../assets/calendarimages/icons/hide.png'),
            }
          )
        "
      ></p>
      <subtitle-template
        id="s2-6"
        :text="
          $t(
            'calendarDocument.TakvimIslemleri.takvimSilinmesi.takvimSilmeTitle'
          )
        "
      ></subtitle-template>
      <p
        v-html="
          $t('calendarDocument.TakvimIslemleri.takvimSilinmesi.takvimSilme', {
            imgPath1: require('../assets/calendarimages/icons/ucnokta.png'),
            imgPath2: require('../assets/calendarimages/icons/delete.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 9"
        imgPath="calendarimages/Resim9.png"
      ></yartu-image>
      <subtitle-template
        id="s2-4"
        :text="
          $t(
            'calendarDocument.TakvimIslemleri.takvimGorunumu.gorunumuDegistirmeTitle'
          )
        "
      ></subtitle-template>
      <p
        v-html="
          $t(
            'calendarDocument.TakvimIslemleri.takvimGorunumu.gorunumunDegistirilmesi'
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 10"
        imgPath="calendarimages/Resim10.png"
      ></yartu-image>
      <p
        v-html="
          $t(
            'calendarDocument.TakvimIslemleri.takvimGorunumu.gorunumunDegistirilmesi2'
          )
        "
      ></p>
      <content-title
        id="s-6"
        :text="
          $t(
            'calendarDocument.YeniEtkinlikOlusturma.olusturma.yeniEtkinlikTitle'
          )
        "
      ></content-title>
      <p
        v-html="
          $t(
            'calendarDocument.YeniEtkinlikOlusturma.olusturma.yeniEtkinlikPenceresi'
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 11"
        imgPath="calendarimages/Resim11.png"
      ></yartu-image>
      <p
        v-html="
          $t(
            'calendarDocument.YeniEtkinlikOlusturma.olusturma.yeniEtkinlikPenceresi2'
          )
        "
      ></p>
      <div class="flex flex-col gap-2 px-10">
        <p
          id="s6-1"
          v-html="
            $t('calendarDocument.YeniEtkinlikOlusturma.olusturma.etkinlikIsmi')
          "
        ></p>
        <p
          id="s6-2"
          v-html="$t('calendarDocument.YeniEtkinlikOlusturma.olusturma.takvim')"
        ></p>
        <p
          id="s6-3"
          v-html="$t('calendarDocument.YeniEtkinlikOlusturma.olusturma.tumGun')"
        ></p>
        <yartu-image
          class=""
          imgName="Resim 12"
          imgPath="calendarimages/Resim12.png"
        ></yartu-image>
        <p
          id="s6-4"
          v-html="$t('calendarDocument.YeniEtkinlikOlusturma.olusturma.Tekrar')"
        ></p>

        <p
          id="s6-5"
          v-html="
            $t('calendarDocument.YeniEtkinlikOlusturma.olusturma.hatirlatici')
          "
        ></p>
        <p
          id="s6-7"
          v-html="$t('calendarDocument.YeniEtkinlikOlusturma.olusturma.konum')"
        ></p>
        <p
          id="s6-8"
          v-html="
            $t('calendarDocument.YeniEtkinlikOlusturma.olusturma.aciklama')
          "
        ></p>
        <p
          id="s6-9"
          v-html="
            $t('calendarDocument.YeniEtkinlikOlusturma.olusturma.katilimci')
          "
        ></p>
      </div>
      <content-title
        id="s7"
        :text="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.title')"
      ></content-title>
      <p
        v-html="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.tekrarEden')"
      ></p>
      <p
        id="s7-1"
        v-html="
          $t('calendarDocument.TekrarEdenEtkinlik.tekrar.tekrarEdenEkleme')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 13"
        imgPath="calendarimages/Resim13.png"
      ></yartu-image>
      <p
        v-html="
          $t('calendarDocument.TekrarEdenEtkinlik.tekrar.tekrarEdenEkleme2')
        "
      ></p>
      <div class="flex flex-col gap-2 px-10">
        <p
          id="s7-2"
          v-html="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.tekrarla')"
        ></p>
        <p v-html="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.gunluk')"></p>
        <p
          v-html="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.haftalik')"
        ></p>
        <yartu-image
          class=""
          imgName="Resim 14"
          imgPath="calendarimages/Resim14.png"
        ></yartu-image>
        <p v-html="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.aylik')"></p>
        <p v-html="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.yillik')"></p>
        <p
          id="s7-3"
          v-html="
            $t('calendarDocument.TekrarEdenEtkinlik.tekrar.tekrarlamaSikligi')
          "
        ></p>
        <p
          id="s7-4"
          v-html="$t('calendarDocument.TekrarEdenEtkinlik.tekrar.bitisZamani')"
        ></p>
        <div class="flex flex-col items-center">
          <div class="flex flex-col gap-2 justify-start">
            <p
              v-html="
                $t('calendarDocument.TekrarEdenEtkinlik.tekrar.hicbirZaman')
              "
            ></p>
            <p
              v-html="
                $t('calendarDocument.TekrarEdenEtkinlik.tekrar.belirtilenTarih')
              "
            ></p>
          </div>
        </div>
        <p
          id="s7-5"
          v-html="
            $t('calendarDocument.TekrarEdenEtkinlik.tekrar.tekrarEdenSilme', {
              imgPath: require('../assets/calendarimages/icons/copkutusupng.png'),
            })
          "
        ></p>
        <p
          v-html="
            $t('calendarDocument.TekrarEdenEtkinlik.tekrar.tekrarEdenSilme2')
          "
        ></p>
      </div>
      <content-title
        id="s8"
        :text="$t('calendarDocument.EtkinlikDegisme.degistirme.title')"
      ></content-title>
      <p
        v-html="
          $t('calendarDocument.EtkinlikDegisme.degistirme.etkinlikDegistirme')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 15"
        imgPath="calendarimages/Resim15.png"
      ></yartu-image>

      <p
        id="s8-1"
        v-html="
          $t(
            'calendarDocument.EtkinlikDegisme.degistirme.etkinlikDegistirme2',
            {
              imgPath: require('../assets/calendarimages/icons/ucnokta.png'),
            }
          )
        "
      ></p>
      <content-title
        id="s9"
        :text="$t('calendarDocument.EtkiknlikSilme.silme.title')"
      >
      </content-title>
      <p
        id="s9-1"
        v-html="
          $t('calendarDocument.EtkiknlikSilme.silme.content', {
            imgPath: require('../assets/calendarimages/icons/ucnokta.png'),
          })
        "
      ></p>
    </template>
    <template #drawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";

import { onMounted, ref, watch } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";

import ComplateDrawer from "@/components/ComplateDrawer.vue";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import { useRoute } from "vue-router";

const drawerStatus = ref(false);

const route = useRoute();

const scrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};
watch(route, (newroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.Id !== "") {
    console.log("data is", route.query.Id);
    scrollToElement(route.query.Id);
  }
});

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Anasayfa ",
    elementId: "s1",
    subtitles: [
      {
        title: "Küçük Takvim",
        elementId: "s1-1",
      },
      {
        title: "Ana Ekran Takvimi",
        elementId: "s1-2",
      },
    ],
  },
  {
    title: "Takvim İşlemleri",
    elementId: "s2",
    subtitles: [
      {
        title: "Yeni Takvim Oluşturma",
        elementId: "s2-1",
        content: [
          {
            title: "Takvim İsmi",
            elementId: "s3-1",
          },
          {
            title: "Renk Seçimi",
            elementId: "s3-2",
          },
          {
            title: "Calved Protokolü",
            elementId: "s3-3",
          },
        ],
      },
      {
        title: "Takvimleri Paylaşma ",
        elementId: "s2-2",
        content: [
          {
            title: "Paylaşım ",
            elementId: "s4-1",
          },
          {
            title: "Paylaşım Yerlerinin Belirlenmesi",
            elementId: "s4-2",
          },
          {
            title: "Paylaşılacak Kişilerin Belirlenmesi",
            elementId: "s4-3",
          },
        ],
      },
      {
        title: "Takvimlerin Düzenlenmesi",
        elementId: "s2-3",
      },
      {
        title: "Takvim Görünürlüğünün Değiştirilmesi",
        elementId: "s2-5",
      },
      {
        title: "Takvimlerin Silinmesi",
        elementId: "s2-6",
      },
      {
        title: "Takvim Görünümünün Düzenlenmesi",
        elementId: "s2-4",
      },
    ],
  },
  {
    title: "Yeni Bir Etkinlik Oluşturma",
    elementId: "s6",
    subtitles: [
      {
        title: "İsim",
        elementId: "s6-1",
      },
      {
        title: "Takvim",
        elementId: "s6-2",
      },
      {
        title: "Tüm Gün",
        elementId: "s6-3",
      },
      {
        title: "Tekrar",
        elementId: "s6-4",
      },
      {
        title: "Hatırlatıcı",
        elementId: "s6-5",
      },
      {
        title: "Konum",
        elementId: "s6-7",
      },
      {
        title: "Açıklama",
        elementId: "s6-8",
      },
      {
        title: "Katılımcılar",
        elementId: "s6-9",
      },
    ],
  },
  {
    title: "Tekrar Eden Etkinlik",
    elemnetId: "s7",
    subtitles: [
      {
        title: "Tekrar Eden Etkinlik Ekleme",
        elementId: "s7-1",
      },
      {
        title: "Tekrarla",
        elementId: "s7-2",
      },
      {
        title: "Tekrarlama Sıklığı",
        elementId: "s7-3",
      },
      {
        title: "Tekrarlanan Etkinliğin Bitiş Zamanı",
        elementId: "s7-4",
      },
      {
        title: "Tekrarlanan Etkinliği Silme",
        elementId: "s7-5",
      },
    ],
  },
  {
    title: "Etkinliğin Değiştirilmesi",
    elementId: "s8",
    subtitles: [
      {
        title: "Değiştirme İşlemi",
        elementId: "s8-1",
      },
    ],
  },
  {
    title: "Etkinliklerin Silinmesi",
    elementId: "s9",
    subtitles: [
      {
        title: "Silme İşlemi",
        elementId: "s9-1",
      },
    ],
  },
]);
</script>
<script></script>
