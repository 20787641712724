<template>
  <span class="flex flex-row items-center gap-2">
    <y-icon name="yi yi-forward"></y-icon>
    <h1 class="font-bold text-2xl py-4 text-BLACK-2">{{ text }}</h1>
  </span>
</template>

<script setup>
import { ref, defineProps } from "vue";
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});
const text = ref(props.text);
</script>
