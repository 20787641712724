import { index } from "./algolia";

const jsonData = require("../src/i18n/tr.json");
console.log(jsonData);

const algoliaMap = [];
const regexp = /(<([^>]+)>)/gi;
const algoliaMapper = (obj) => {
  //
  if ("mainTitle" in obj) {
    const mappedData = {};
    mappedData["mainTitle"] = obj["mainTitle"];
    mappedData["title"] = obj["title"];
    mappedData["elementId"] = obj["elementId"];
    mappedData["route"] = obj["route"];
    mappedData["content"] = "";

    Object.keys(obj).forEach((objkey) => {
      if (
        !["mainTitle", "title", "elementId", "route", "content"].includes(
          objkey
        )
      ) {
        const content = obj[objkey];
        const trimmedContent = content.replace(regexp, "");
        mappedData["content"] += trimmedContent;
      }
    });
    algoliaMap.push(mappedData);
  } else if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((objk) => {
      if (typeof obj[objk] === "object") {
        algoliaMapper(obj[objk]);
      }
    });
  }
};
algoliaMapper(jsonData);
console.log("asdasd" + algoliaMap);

async function uploadData() {
  try {
    const objects = algoliaMap.map((data, i) => ({
      objectID: i + 1,
      ...data,
    }));

    const response = await index.saveObjects(objects);
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}

export default uploadData;
