<template>
  <div class="h-max">
    <div
      :class="isSubtitle ? '' : 'pl-3 hover:scale-110'"
      class="flex flex-row items-center transform transition-transform hover:bg-zinc-100"
    >
      <y-icon
        :class="isSubtitle ? 'text-xs pl-2' : ''"
        name="yi yi-arrow-right "
      ></y-icon>
      <div class="">
        <slot name="activator" :open="open"> </slot>
      </div>
    </div>

    <div
      class="transition-all ease-in-out duration-300"
      :class="
        openAccordion
          ? 'opacity-100  max-h-[200px] overflow-scroll ' + isContent
            ? 'max-h-[9999px] '
            : 'max-h-[200px] overflow-scroll '
          : 'max-h-0 opacity-0 overflow-hidden'
      "
    >
      <slot name="content" />

      <hr />
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
  collapse: {
    type: Boolean,
    default: false,
    required: false,
  },
  content: {
    type: Boolean,
    required: false,
    default: false,
  },
  isSubtitle: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const isSubtitle = ref(props.isSubtitle);
const openAccordion = ref(props.collapse);
const isContent = ref(props.content);

const open = () => {
  openAccordion.value = !openAccordion.value;
};
</script>
