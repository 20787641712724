<template>
  <yartu-accordion
    :menu="menus"
    class=""
    :collapse="false"
    :content="false"
    v-for="(menu, index) in menus"
    :key="index"
  >
    <template #activator="{ open }">
      <y-dropdown-drawer-item class="gap-1 !py-4" @click="open">
        <p class="font-semibold text-lg">{{ menu.title }}</p>
      </y-dropdown-drawer-item>
    </template>
    <template #content>
      <template v-for="(subtitle, subIndex) in menu.subtitles" :key="subIndex">
        <template v-if="subtitle.content && subtitle.content.length > 0">
          <yartu-accordion
            class="overflow-visible"
            :collapse="false"
            :content="true"
            isSubtitle
          >
            <template #activator="{ open }">
              <y-dropdown-drawer-item class="gap-1" @click="open">
                <p class="text-[13px] font-normal">{{ subtitle.title }}</p>
              </y-dropdown-drawer-item>
            </template>
            <template #content>
              <drawer-item
                v-for="(content, index) in subtitle.content"
                :key="index"
                :content="content.title"
                @click="handleClick(content.elementId)"
              ></drawer-item>
            </template>
          </yartu-accordion>
        </template>
        <template v-else>
          <drawer-item
            :content="subtitle.title"
            @click="handleClick(subtitle.elementId)"
          ></drawer-item>
        </template>
      </template>
    </template>
  </yartu-accordion>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted } from "vue";
import YartuAccordion from "./YartuAccordion.vue";
import DrawerItem from "./DrawerItem.vue";

const props = defineProps({
  menuList: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["clicked"]);

const menus = ref(props.menuList);

const handleClick = (elementId) => {
  emit("clicked", elementId);
};
onMounted(() => {
  console.log(menus.value);
});
</script>
