<template>
  <pagetemplate>
    <template #smallDrawer>
      <div>
        <div>
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
    <template #content>
      <content-title :text="$t('contactDocument.anasayfa.baslik')">
      </content-title>
      <p
        id="s1-1"
        v-html="
          $t('calendarDocument.Anasayfa.anaEkran', {
            imgPath: require('../assets/contactimages/icons/rehber.png'),
          })
        "
      ></p>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <p v-html="$t('contactDocument.anasayfa.drawer')"></p>
          <yartu-image
            class="w-full xs:hidden"
            imgName="Resim 1"
            imgPath="contactimages/Resim1.png"
          ></yartu-image>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 1"
            imgPath="contactimages/Resim1.png"
          ></yartu-image>
        </div>
      </div>
      <p v-html="$t('contactDocument.anasayfa.drawer2')"></p>
      <p id="s1-2" v-html="$t('contactDocument.anasayfa.olustur')"></p>
      <content-title :text="$t('contactDocument.kisiIslemleri.baslik')">
      </content-title>
      <subtitle-template
        id="s2-1"
        :text="$t('contactDocument.kisiIslemleri.kisiEkleBaslik')"
      >
      </subtitle-template>
      <p v-html="$t('contactDocument.kisiIslemleri.kisiEkle')"></p>
      <yartu-image
        class=""
        imgName="Resim 2"
        imgPath="contactimages/Resim2.png"
      ></yartu-image>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 3"
            imgPath="contactimages/Resim3.png"
          ></yartu-image>
          <p v-html="$t('contactDocument.kisiIslemleri.kisiEkle2')"></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 3"
            imgPath="contactimages/Resim3.png"
          ></yartu-image>
        </div>
      </div>
      <p v-html="$t('contactDocument.kisiIslemleri.kaydet')"></p>
      <p
        v-html="
          $t('contactDocument.kisiIslemleri.yeniKisiButon', {
            imgPath1: require('../assets/contactimages/icons/new-user.png'),
          })
        "
      ></p>
      <subtitle-template
        id="s2-2"
        :text="$t('contactDocument.kisiIslemleri.duzenlemeBaslik')"
      ></subtitle-template>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 4"
            imgPath="contactimages/Resim4.png"
          ></yartu-image>
          <p
            v-html="
              $t('contactDocument.kisiIslemleri.duzenle', {
                imgPath: require('../assets/contactimages/icons/ucnokta.png'),
              })
            "
          ></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 4"
            imgPath="contactimages/Resim4.png"
          ></yartu-image>
        </div>
      </div>

      <p v-html="$t('contactDocument.kisiIslemleri.duzenle2')"></p>
      <yartu-image
        class=""
        imgName="Resim 5"
        imgPath="contactimages/Resim6.png"
      ></yartu-image>
      <subtitle-template
        id="s2-3"
        :text="$t('contactDocument.kisiIslemleri.tasimaBaslik')"
      ></subtitle-template>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 6"
            imgPath="contactimages/Resim7.png"
          ></yartu-image>
          <p
            v-html="
              $t('contactDocument.kisiIslemleri.tasima', {
                imgPath: require('../assets/contactimages/icons/ucnokta.png'),
              })
            "
          ></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 6"
            imgPath="contactimages/Resim7.png"
          ></yartu-image>
        </div>
      </div>
      <subtitle-template
        id="s2-4"
        :text="$t('contactDocument.kisiIslemleri.disaAktarBaslik')"
      ></subtitle-template>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 7"
            imgPath="contactimages/Resim8.png"
          ></yartu-image>
          <p
            v-html="
              $t('contactDocument.kisiIslemleri.disaAktar', {
                imgPath: require('../assets/contactimages/icons/ucnokta.png'),
              })
            "
          ></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 7"
            imgPath="contactimages/Resim8.png"
          ></yartu-image>
        </div>
      </div>
      <subtitle-template
        id="s2-5"
        :text="$t('contactDocument.kisiIslemleri.etiketBaslik')"
      ></subtitle-template>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col pt-8 items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 8"
            imgPath="contactimages/Resim13.png"
          ></yartu-image>
          <p
            v-html="
              $t('contactDocument.kisiIslemleri.etiket', {
                imgPath: require('../assets/contactimages/icons/ucnokta.png'),
              })
            "
          ></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 8"
            imgPath="contactimages/Resim13.png"
          ></yartu-image>
        </div>
      </div>
      <p v-html="$t('contactDocument.kisiIslemleri.etiketEkleTekli')"></p>
      <yartu-image
        class=""
        imgName="Resim 8"
        imgPath="contactimages/yeni_etiket.png"
      ></yartu-image>
      <subtitle-template
        id="s2-6"
        :text="$t('contactDocument.kisiIslemleri.silmeBaslik')"
      ></subtitle-template>
      <p
        v-html="
          $t('contactDocument.kisiIslemleri.silme', {
            imgPath: require('../assets/contactimages/icons/ucnokta.png'),
          })
        "
      ></p>
      <subtitle-template
        id="s3-0"
        :text="$t('contactDocument.kisiIslemleri.topluBaslik')"
      ></subtitle-template>
      <p v-html="$t('contactDocument.kisiIslemleri.toplu1')"></p>
      <yartu-image
        class=""
        imgName="Resim 9"
        imgPath="contactimages/Resim9.png"
      ></yartu-image>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 9"
            imgPath="contactimages/Resim10.png"
          ></yartu-image>
          <p
            id="s3-1"
            v-html="$t('contactDocument.kisiIslemleri.birlestir')"
          ></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 10"
            imgPath="contactimages/Resim10.png"
          ></yartu-image>
        </div>
      </div>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 11"
            imgPath="contactimages/Resim11.png"
          ></yartu-image>
          <p
            id="s3-2"
            v-html="$t('contactDocument.kisiIslemleri.mailGonder')"
          ></p>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 11"
            imgPath="contactimages/Resim11.png"
          ></yartu-image>
        </div>
      </div>
      <p id="s3-3" v-html="$t('contactDocument.kisiIslemleri.etiketEkle')"></p>
      <p v-html="$t('contactDocument.kisiIslemleri.diger')"></p>
      <div class="flex flex-row gap-6 mx-auto">
        <div class="flex flex-col justify-center items-start gap-2 w-full">
          <yartu-image
            class="xs:hidden"
            imgName="Resim 12"
            imgPath="contactimages/Resim12.png"
          ></yartu-image>
          <div class="px-8 py-2">
            <p
              id="s3-4"
              v-html="$t('contactDocument.kisiIslemleri.yildizEkle')"
            ></p>
            <p
              id="s3-5"
              v-html="$t('contactDocument.kisiIslemleri.yildizKaldir')"
            ></p>
            <p
              id="s3-6"
              v-html="$t('contactDocument.kisiIslemleri.surayaTasi')"
            ></p>
            <p
              id="s3-7"
              v-html="$t('contactDocument.kisiIslemleri.cokluDisaAktar')"
            ></p>
            <p id="s3-8" v-html="$t('contactDocument.kisiIslemleri.sil')"></p>
          </div>
        </div>
        <div class="xs:block hidden w-full">
          <yartu-image
            class=""
            imgName="Resim 12"
            imgPath="contactimages/Resim12.png"
          ></yartu-image>
        </div>
      </div>
    </template>
    <template #drawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";

import { onMounted, ref, watch } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";

import ComplateDrawer from "@/components/ComplateDrawer.vue";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import { useRoute } from "vue-router";

const drawerStatus = ref(false);

const route = useRoute();

const scrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};
watch(route, (newroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.Id !== "") {
    console.log("data is", route.query.Id);
    scrollToElement(route.query.Id);
  }
});

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Anasayfa ",
    elementId: "s1",
    subtitles: [
      {
        title: "Menü",
        elementId: "s1-1",
      },
      {
        title: "Yeni Ekleme",
        elementId: "s1-2",
      },
    ],
  },
  {
    title: "Kişi İşlemleri",
    elementId: "s2",
    subtitles: [
      {
        title: "Kişi Ekleme",
        elementId: "s2-1",
      },
      {
        title: "Kişi Düzenleme",
        elementId: "s2-2",
      },
      {
        title: "Kişileri Adres Defterine Taşı",
        elementId: "s2-3",
      },
      {
        title: "Kişileri Dışa Aktarma",
        elementId: "s2-4",
      },
      {
        title: "Kişilere Etiket Ekleme",
        elementId: "s2-5",
      },
      {
        title: "Kİşileri Silme",
        elementId: "s2-6",
      },
      {
        title: "Çoklu İşlemler ",
        elementId: "s2-7",
        content: [
          {
            title: "İşlem Çubuğu ",
            elementId: "s3-0",
          },
          {
            title: "Birleştirme ",
            elementId: "s3-1",
          },
          {
            title: "E-posta Gönderme",
            elementId: "s3-2",
          },
          {
            title: "Etiket Ekleme",
            elementId: "s3-3",
          },
          {
            title: "Yıldız Ekleme",
            elementId: "s3-4",
          },
          {
            title: "Yıldız Kaldırma",
            elementId: "s3-5",
          },
          {
            title: "Şuraya Taşı",
            elementId: "s3-6",
          },
          {
            title: "Dışa Aktar",
            elementId: "s3-7",
          },
          {
            title: "Sil",
            elementId: "s3-8",
          },
        ],
      },
    ],
  },
]);
</script>
<script></script>
