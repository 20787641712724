<template>
  <y-card
    @click="$router.push({ path: path, query: { Id } })"
    class="rounded-lg py-3 px-4 xs:px-12 flex flex-row w-full items-start 4xs:items-center gap-2 hover:bg-blue-50"
  >
    <y-icon class="4xs:pt-0 pt-1" name="yi yi-label-tag"></y-icon> {{ text }}
  </y-card>
</template>

<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  id: {
    type: String,
  },
  path: {
    type: String,
  },
  text: {
    type: String,
  },
});

const Id = ref(props.id);
const path = ref(props.path);
</script>
