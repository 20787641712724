<template>
  <div class="flex flex-row">
    <div class="flex justify-end items-start min-w-fit">
      <img v-if="imageSrc !== ''" class="pr-2 w-9" :src="imageSrc" />
      <y-icon class="p-2" v-if="iconname !== ''" :name="[iconname]"></y-icon>
      <p class="pl-1">:</p>
    </div>
    <p class="flex items-center">&nbsp;{{ sentence }}</p>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue";

const props = defineProps({
  imgPath: {
    type: String,
    required: false,
    default: "",
  },
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: "",
  },
});
const iconname = ref(props.icon);
const imageSrc = computed(() => {
  if (props.icon) return "";
  else return require(`../assets/${props.imgPath}`);
});
const sentence = ref(props.text);
</script>
