<template>
  <div class="flex flex-col">
    <transition name="slide-down">
      <div
        v-show="showAnimation"
        class="flex flex-row w-full gap-5 justify-center items-center py-12"
      >
        <img class="scale-150" :src="imageSrc" />
        <p class="text-[32px] font-bold text-BLACK-2">
          <span class="text-BLUE">Yartu</span> {{ mainTitle }}
        </p>
      </div>
    </transition>
    <div
      class="flex flex-row justify-between gap-4 p-4 w-full sm:p-0 mx-auto sm:max-w-[1024px] sm:min-w-[960px] z-10"
    >
      <div class="pb-4 w-full sm:max-w-[800px] pt-2 overflow-hidden">
        <div class="flex flex-col gap-4">
          <y-button
            secondary
            class="border-white sm:hidden mr-4 scale-125 fixed top-16 z-51 right-2"
            circle
            icon="yi yi-menu-outline text-lg"
            @click="openDrawer"
          ></y-button>
          <y-navigation-drawer
            :open="open"
            right
            fixed
            overlay
            rounded
            @collapse="openDrawer"
          >
            <slot name="smallDrawer" class=""></slot>
          </y-navigation-drawer>

          <slot name="content" class=""></slot>

          <div class="pt-10 text-lg flex justify-center">
            Aradığınızı bulamadınız mı? &nbsp;

            <a
              class="text-blue-700 inline cursor-pointer font-semibold"
              href="https://support.yartu.io/#login"
              target="_blank"
            >
              Bize ulaşın</a
            >
          </div>
        </div>
      </div>

      <div class="min-w-[220px] md:block hidden">
        <div class="h-6 bg-white"></div>
        <div id="drawer" class="sticky z-50 top-16">
          <div class="rounded-3xl h-screen">
            <y-navigation-drawer
              :rounded="true"
              class="bottom-0 !bg-white"
              open
            >
              <slot name="drawer"></slot>
            </y-navigation-drawer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps } from "vue";
const open = ref(false);

const props = defineProps({
  mainTitle: {
    type: String,
    required: true,
    default: "Rehber",
  },
  imgPath: {
    type: String,
    required: true,
    default: "rehber.svg",
  },
});

const showAnimation = ref(false);

const imageSrc = ref(require(`../assets/icons/${props.imgPath}`));
const openDrawer = () => {
  if (open.value == false) {
    open.value = true;
  } else open.value = false;
};

onMounted(() => {
  setTimeout(() => {
    showAnimation.value = true;
  });

  if (window.innerWidth >= 768) {
    open.value = false;
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth >= 768) {
      open.value = false;
    }
  });
});
</script>

<style>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 1.5s ease;
}
.slide-down-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-down-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
