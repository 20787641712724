<template>
  <div class="flex flex-col justify-center sticky z-40 top-0 my-0">
    <div class="w-full bg-white my-0">
      <div
        class="h-16 w-full flex flex-row items-center mx-auto justify-between max-w-[1024px]"
      >
        <div
          class="flex flex-row items-center w-28 cursor-pointer"
          @click="$router.push('/')"
        >
          <img
            class="3xs:w-36 w-22"
            src="../assets/icons/Group.png"
            alt="logo"
          />
        </div>
        <div
          v-if="$route.name != 'home'"
          class="4xs:flex 4xs:flex-col 4xs:w-1/3 hidden"
        >
          <div class="text-2xs 2xs:text-xs hidden 3xs:flex justify-center">
            Aradığınızı bulamadınız mı? &nbsp;

            <a
              class="text-blue-700 inline cursor-pointer font-semibold"
              href="https://support.yartu.io/#login"
              target="_blank"
            >
              Bize ulaşın</a
            >
          </div>
          <search-input class=""> </search-input>
        </div>

        <div class="flex flex-row">
          <div
            class="4xs:pl-8 4xs:pr-3 4xs:flex gap-6 flex flex-row"
            :class="showSearchModal ? 'hidden' : 'block'"
          >
            <nav
              class="hover:text-blue-500 font-semibold text-xs text-BLACK-2 sm:text-base my-auto"
            >
              <RouterLink to="/">Anasayfa</RouterLink>
            </nav>
            <span class="border-r-2"></span>

            <y-dropdown left>
              <y-dropdown-item @click="$router.push('/email')">
                E-Posta
              </y-dropdown-item>
              <y-dropdown-item @click="$router.push('/storage')">
                Depolama
              </y-dropdown-item>
              <y-dropdown-item @click="$router.push('/calendar')">
                Takvim
              </y-dropdown-item>
              <y-dropdown-item @click="$router.push('/project')">
                Proje
              </y-dropdown-item>
              <y-dropdown-item @click="$router.push('/note')">
                Not
              </y-dropdown-item>
              <y-dropdown-item @click="$router.push('/contact')">
                Rehber
              </y-dropdown-item>
              <y-dropdown-item @click="$router.push('/conferance')">
                Konferans
              </y-dropdown-item>
              <y-dropdown-item @click="$router.push('/syncronise')">
                Senkronizasyon
              </y-dropdown-item>
              <template #activator="{ open }">
                <div class="flex h-full justify-center items-center">
                  <button
                    class="hover:text-blue-500 text-xs text-BLACK-2 sm:text-base font-semibold"
                    @click="open"
                  >
                    Modüller
                  </button>
                </div>
              </template>
            </y-dropdown>
          </div>
          <div class="flex flex-row">
            <div
              class="transition-all ease-in-out duration-600 overflow-hidden"
            >
              <y-button
                secondary
                class="border-white 4xs:hidden"
                circle
                icon="yi yi-search"
                @click="openSearch"
              ></y-button>
            </div>

            <div
              class="transition-all ease-in-out duration-600 overflow-hidden"
              :class="
                showSearchModal
                  ? 'translate-x-0 opacity-100'
                  : 'translate-x-full opacity-0 '
              "
            >
              <search-input v-if="showSearchModal"> </search-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import SearchInput from "@/views/SearchInput.vue";

const showDropdown = ref(false);
const showSearchModal = ref(false);

const openDropdown = () => {
  if (showDropdown.value == false) showDropdown.value = true;
  else showDropdown.value = false;
};
const openSearch = () => {
  if (showSearchModal.value == false) showSearchModal.value = true;
  else showSearchModal.value = false;
};
onMounted(() => {
  if (window.innerWidth >= 475) {
    showSearchModal.value = false;
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth >= 475) {
      showSearchModal.value = false;
    }
  });
});
</script>
