<template>
  <ais-instant-search
    class="flex flex-col gap-0"
    index-name="apideneme"
    :search-client="searchClient"
    :insights="true"
  >
    <ais-configure :hits-per-page.camel="5" />
    <ais-search-box
      class="w-full"
      :class="{ focus: focused }"
      @focus="focused = false"
      @input="onSearchInput"
    />
    <div>
      <ais-hits
        v-if="focused"
        @focus="focused = true"
        class="h-1 !p-0 hover:cursor-pointer"
      >
        <template v-slot:item="{ item }">
          <div
            :key="i"
            @click="clickeda(item), (focused = false)"
            class="hover:cursor-pointer z-50 flex flex-col hover:bg-blue-50 hover:text-blue-500"
          >
            <ais-highlight attribute="title" :hit="item" class="flex w-full" />

            <ais-highlight
              attribute="mainTitle"
              :hit="item"
              class="text-zinc-500 text-xs"
            />
          </div>
        </template>
      </ais-hits>
    </div>
  </ais-instant-search>
</template>

<script setup>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite-min.css";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const goToContent = (link, Id) => {
  console.log(Id);
  router.push({ path: link, query: { Id } });
};

const focused = ref(false);

const searchClient = ref(
  algoliasearch("GAHFGLK8XW", "71228e68be199e068f3020147a00d429")
);
const onSearchInput = () => {
  focused.value = true;
};
const clickeda = (item) => {
  const Id = item.elementId;
  console.log(item.elementId);
  console.log("aaa");
  router.push({ path: `${item.route}`, query: { Id } });
};
</script>

<style>
.ais-Hits-item {
  /* Mevcut sınıfın özelliklerini korurken ekstra stil eklemek için @apply kullanımı */
  /* Örnek: */
  padding: 5px;
  display: block;
}
</style>
