<template>
  <y-dropdown-drawer-item
    class="gap-1"
    prefix="yi yi-minus"
    postfix="yi yi-arrow-right"
  >
    <h1 class="text-[13px] font-normal">{{ content }}</h1>
  </y-dropdown-drawer-item>
</template>

<script setup>
import { ref, defineProps } from "vue";
const props = defineProps({
  content: {
    type: String,
    default: "empty",
    required: true,
  },
});
const myelementId = ref(props.elementId);
const content = ref(props.content);
</script>
