<template>
  <pagetemplate mainTitle="Konferans" imgPath="videoKonferans.svg">
    <template #smallDrawer>
      <div>
        <div>
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
    <template #content>
      <content-title :text="$t('ConferanceDocument.GenelBakis.title')">
      </content-title>
      <p v-html="$t('ConferanceDocument.GenelBakis.aciklama')"></p>
      <subtitle-template
        id="s1-1"
        :text="$t('ConferanceDocument.GenelBakis.Anasayfa.title')"
      ></subtitle-template>
      <p v-html="$t('ConferanceDocument.GenelBakis.Anasayfa.aciklama')"></p>
      <yartu-image
        class=""
        imgName="Resim 1"
        imgPath="conferanceimages/Resim1.png"
      ></yartu-image>
      <subtitle-template
        id="s1-2"
        :text="$t('ConferanceDocument.GenelBakis.Konferanslarim.title')"
      ></subtitle-template>
      <p
        v-html="$t('ConferanceDocument.GenelBakis.Konferanslarim.aciklama')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 2"
        imgPath="conferanceimages/Resim2.png"
      ></yartu-image>
      <p
        v-html="$t('ConferanceDocument.GenelBakis.Konferanslarim.aciklama2')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 3"
        imgPath="conferanceimages/Resim3.png"
      ></yartu-image>
      <subtitle-template
        id="s1-3"
        :text="$t('ConferanceDocument.GenelBakis.DavetliKonferans.title')"
      ></subtitle-template>
      <p
        v-html="$t('ConferanceDocument.GenelBakis.DavetliKonferans.aciklama')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 4"
        imgPath="conferanceimages/Resim4.png"
      ></yartu-image>
      <subtitle-template
        id="s1-4"
        :text="$t('ConferanceDocument.GenelBakis.Kayitlar.title')"
      ></subtitle-template>
      <p v-html="$t('ConferanceDocument.GenelBakis.Kayitlar.aciklama')"></p>
      <p v-html="$t('ConferanceDocument.GenelBakis.Kayitlar.aciklama2')"></p>
      <yartu-image
        class=""
        imgName="Resim 5"
        imgPath="conferanceimages/Resim28.png"
      ></yartu-image>
      <content-title :text="$t('ConferanceDocument.KonferansOlusturma.title')">
      </content-title>
      <subtitle-template
        id="s2-1"
        :text="
          $t('ConferanceDocument.KonferansOlusturma.KonferansPlanlama.title')
        "
      ></subtitle-template>
      <p
        v-html="
          $t('ConferanceDocument.KonferansOlusturma.KonferansPlanlama.butonlar')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 6"
        imgPath="conferanceimages/Resim5.png"
      ></yartu-image>
      <p
        v-html="
          $t('ConferanceDocument.KonferansOlusturma.KonferansPlanlama.bilgi')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 7"
        imgPath="conferanceimages/Resim6.png"
      ></yartu-image>
      <p
        v-html="
          $t('ConferanceDocument.KonferansOlusturma.KonferansPlanlama.tekrar')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 8"
        imgPath="conferanceimages/Resim7.png"
      ></yartu-image>
      <p
        v-html="
          $t('ConferanceDocument.KonferansOlusturma.KonferansPlanlama.sure')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 9"
        imgPath="conferanceimages/Resim8.png"
      ></yartu-image>
      <p
        v-html="
          $t(
            'ConferanceDocument.KonferansOlusturma.KonferansPlanlama.moderator'
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 10"
        imgPath="conferanceimages/Resim9.png"
      ></yartu-image>
      <subtitle-template
        id="s2-2"
        :text="$t('ConferanceDocument.KonferansOlusturma.AnlikKonferans.title')"
      ></subtitle-template>
      <p
        v-html="
          $t('ConferanceDocument.KonferansOlusturma.AnlikKonferans.erisim')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 11"
        imgPath="conferanceimages/Resim10.png"
      ></yartu-image>
      <p>
        <span>
          {{
            $t("ConferanceDocument.KonferansOlusturma.AnlikKonferans.detay")
          }}</span
        >
        <span
          class="text-BLUE font-semibold cursor-pointer"
          @click="scrollToElement('s4-1')"
        >
          {{
            $t("ConferanceDocument.KonferansOlusturma.AnlikKonferans.detay2")
          }}
        </span>
        <span>
          {{
            $t("ConferanceDocument.KonferansOlusturma.AnlikKonferans.detay3")
          }}
        </span>
      </p>
      <yartu-image
        class=""
        imgName="Resim 12"
        imgPath="conferanceimages/Resim11.png"
      ></yartu-image>
      <content-title :text="$t('ConferanceDocument.KonferansDetaylari.title')">
      </content-title>
      <subtitle-template
        id="s3-1"
        :text="
          $t('ConferanceDocument.KonferansDetaylari.YaklasanKonferans.title')
        "
      ></subtitle-template>
      <p
        v-html="
          $t(
            'ConferanceDocument.KonferansDetaylari.YaklasanKonferans.aciklama',
            {
              imgPath: require('../assets/conferanceimages/sil.png'),
            }
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 13"
        imgPath="conferanceimages/Resim12.png"
      ></yartu-image>
      <p
        v-html="
          $t('ConferanceDocument.KonferansDetaylari.YaklasanKonferans.detay')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 14"
        imgPath="conferanceimages/Resim13.png"
      ></yartu-image>
      <p
        v-html="
          $t(
            'ConferanceDocument.KonferansDetaylari.YaklasanKonferans.mikrofon',
            {
              imgPath1: require('../assets/conferanceimages/mic.png'),
              imgPath2: require('../assets/conferanceimages/cam.png'),
              imgPath3: require('../assets/conferanceimages/ayarlar.png'),
            }
          )
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 15"
        imgPath="conferanceimages/Resim14.png"
      ></yartu-image>
      <p
        v-html="
          $t(
            'ConferanceDocument.KonferansDetaylari.YaklasanKonferans.baslangic'
          )
        "
      ></p>
      <subtitle-template
        id="s3-2"
        :text="
          $t('ConferanceDocument.KonferansDetaylari.TamamlananKonferans.title')
        "
      ></subtitle-template>
      <p
        v-html="
          $t('ConferanceDocument.KonferansDetaylari.TamamlananKonferans.liste')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 16"
        imgPath="conferanceimages/Resim15.png"
      ></yartu-image>
      <p
        v-html="
          $t('ConferanceDocument.KonferansDetaylari.TamamlananKonferans.detay')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 17"
        imgPath="conferanceimages/Resim16.png"
      ></yartu-image>
      <p
        v-html="
          $t('ConferanceDocument.KonferansDetaylari.TamamlananKonferans.sil')
        "
      ></p>
      <content-title :text="$t('ConferanceDocument.KonferansEkrani.title')">
      </content-title>
      <subtitle-template
        id="s4-1"
        :text="$t('ConferanceDocument.KonferansEkrani.GenelBakis.title')"
      ></subtitle-template>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.GenelBakis.aciklama')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 18"
        imgPath="conferanceimages/Resim17.png"
      ></yartu-image>
      <subtitle-template
        id="s4-2"
        :text="$t('ConferanceDocument.KonferansEkrani.AracCubugu.title')"
      ></subtitle-template>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.mikrofon')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 19"
        imgPath="conferanceimages/Resim18.png"
      ></yartu-image>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.kamera')"
      ></p>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.ekranPaylas')"
      ></p>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.sohbet')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 19"
        imgPath="conferanceimages/Resim19.png"
      ></yartu-image>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.secenek')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 20"
        imgPath="conferanceimages/Resim20.png"
      ></yartu-image>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.elKaldir')"
      ></p>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.layout')"
      ></p>
      <p v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.menu')"></p>
      <p
        v-html="
          $t('ConferanceDocument.KonferansEkrani.AracCubugu.katilimcilar')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 21"
        imgPath="conferanceimages/Resim21.png"
      ></yartu-image>
      <p v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.admin')"></p>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.mutaAll')"
      ></p>
      <p v-html="$t('ConferanceDocument.KonferansEkrani.AracCubugu.oda')"></p>
      <p
        v-html="
          $t('ConferanceDocument.KonferansEkrani.AracCubugu.kullaniciMenu')
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 22"
        imgPath="conferanceimages/Resim22.png"
      ></yartu-image>
      <subtitle-template
        id="s4-3"
        :text="$t('ConferanceDocument.KonferansEkrani.DetayEkrani.title')"
      ></subtitle-template>
      <p
        v-html="$t('ConferanceDocument.KonferansEkrani.DetayEkrani.detay')"
      ></p>
      <yartu-image
        class=""
        imgName="Resim 23"
        imgPath="conferanceimages/Resim24.png"
      ></yartu-image>
      <p
        v-html="
          $t('ConferanceDocument.KonferansEkrani.DetayEkrani.katilimci', {
            imgPath2: require('../assets/conferanceimages/moderator.png'),
          })
        "
      ></p>
      <yartu-image
        class=""
        imgName="Resim 24"
        imgPath="conferanceimages/Resim25.png"
      ></yartu-image>
      <p v-html="$t('ConferanceDocument.KonferansEkrani.DetayEkrani.not')"></p>
    </template>
    <template #drawer>
      <div>
        <div class="">
          <complate-drawer
            :menuList="menus"
            @clicked="scrollToElement"
          ></complate-drawer>
        </div>
      </div>
    </template>
  </pagetemplate>
</template>

<script setup>
import Pagetemplate from "../components/PageTemplate.vue";

import { onMounted, ref, watch } from "vue";
import { scrollTo } from "vue-scrollto";
import YartuImage from "../components/YartuImage.vue";
import contentTitle from "../components/ContentTitle.vue";

import ComplateDrawer from "@/components/ComplateDrawer.vue";
import SubtitleTemplate from "@/components/SubtitleTemplate.vue";
import { useRoute } from "vue-router";
import SentaceWithImage from "../components/SentaceWithImage.vue";

const drawerStatus = ref(false);

const route = useRoute();

const scrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
};
watch(route, (newroute) => {
  if (newroute.Id !== "") {
    scrollToElement(route.query.Id);
  }
});
onMounted(() => {
  if (route.query.Id !== "") {
    console.log("data is", route.query.Id);
    scrollToElement(route.query.Id);
  }
});

const smallDrawerScrollToElement = (elementId) => {
  scrollTo("#" + elementId, 500, {
    easing: "ease-in-out",
    offset: -70,
  });
  if (drawerStatus.value == true) drawerStatus.value = false;
  else drawerStatus.value = true;
};
const menus = ref([
  {
    title: "Genel Bakış ",
    elementId: "s1",
    subtitles: [
      {
        title: "Anasayfa",
        elementId: "s1-1",
      },
      {
        title: "Konferanslarm",
        elementId: "s1-2",
      },
      {
        title: "Davetli Konferanslarım",
        elementId: "s1-3",
      },
    ],
  },
  {
    title: "Konferans Oluşturma",
    elementId: "s2",
    subtitles: [
      {
        title: "Planlama",
        elementId: "s2-1",
      },
      {
        title: "Anlık Konferans",
        elementId: "s2-2",
      },
    ],
  },
  {
    title: "Konferans Detayları",
    elementId: "s3",
    subtitles: [
      {
        title: "Yaklaşan",
        elementId: "s3-1",
      },
      {
        title: "Tamamlanan",
        elementId: "s3-2",
      },
    ],
  },
  {
    title: "Konferans Ekranı",
    elementId: "s4",
    subtitles: [
      {
        title: "Genel Bakış",
        elementId: "s4-1",
      },
      {
        title: "Araç Çubuğu",
        elementId: "s4-2",
      },
      {
        title: "Detay Ekranı",
        elementId: "s4-3",
      },
    ],
  },
]);
</script>
<script></script>
